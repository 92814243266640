import React, { useEffect, useState } from 'react';

import { storyblokEditable } from '@storyblok/react';
// import kebabCase from 'lodash/kebabCase';
import ReactMarkdown from 'react-markdown';

import { Gallery } from 'components/common-n4/gallery';
import BluePlayButton from 'images/blue-play-button.svg';
import WhitePlayButton from 'images/white-play-button.svg';
import YouTubePlayButton from 'images/youtube-play-button.svg';
import logger from 'lib/logger';
import { cx, normalizeUrl, tw } from 'lib/utils';

import styles from './youtube.module.scss';

const log = logger({ category: 'youtube' });

const parseThumbnails = (blok) => {
  const thumbnails = [];
  if (blok.thumbnail_override?.filename) {
    // TODO: should this be going through our ImageWrapper?
    const link = normalizeUrl({ url: blok.thumbnail_override.filename, origin: 'n4youtube#parseThumbnails' });
    thumbnails.push(`${link.replace(/\/\/a.storyblok.com/, '//img2.storyblok.com/1280x0/filters:quality(40)')} 1280w`);
    thumbnails.push(`${link.replace(/\/\/a.storyblok.com/, '//img2.storyblok.com/640x0/filters:quality(40)')} 640w`);
    thumbnails.push(`${link.replace(/\/\/a.storyblok.com/, '//img2.storyblok.com/480x0/filters:quality(40)')} 480w`);
    return thumbnails;
  }

  const thumbnailData = [];
  Object.values(blok.video_url.youtube_oembed.response.items[0].snippet.thumbnails).forEach((data) => {
    if (data.width) {
      thumbnailData.push({ width: Number(data.width * 0.5), url: data.url });
    }
  });
  thumbnailData
    .sort((a, b) => Math.sign(b.width - a.width))
    .forEach((thumbnail) => {
      const url = normalizeUrl({ url: thumbnail.url, origin: 'n4youtube#thumbnailData' }).replace(
        /\/\/a.storyblok.com/,
        `//img2.storyblok.com/${thumbnail.width}x0/filters:quality(40)`
      );
      thumbnails.push(`${url} ${thumbnail.width}w`);
    });
  return thumbnails;
};

const loadScript = () => {
  const src = 'https://www.youtube.com/iframe_api';
  if (document.querySelector(`[src="${src}"]`)) {
    log.debug('script already loaded');
    return;
  }
  log.debug('loading script');
  const tag = document.createElement('script');
  tag.setAttribute('id', 'youtube-iframe-api');
  tag.src = src;
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

// const onPlayerReady = (event) => {
//   // event.target is the player
//   const player = event.target;
//   const videoId = player.getVideoData().video_id;
//   if (new URLSearchParams(window.location.search).get('resize') === 'true') {
//     const containerId = `c-${videoId}`;
//     const el = document.getElementById(containerId);
//     el.style.width = '';
//     el.style.height = '';
//     el.style.display = 'block';
//   }
// };

/* eslint-disable-next-line no-unused-vars */
const onPlayerStateChange = (event) => {
  // event.data is the state of the player
  // I assume event.target is the player itself
  // Not sure what we would want to do here
  /*
     YT.PlayerState.ENDED
     YT.PlayerState.PLAYING
     YT.PlayerState.PAUSED
     YT.PlayerState.BUFFERING
     YT.PlayerState.CUED
  */
};

const VideoLink = ({ videoId, title, removeBars, redPlay, image, thumbnails, setModalShowing, ready, bluePlay = false, whitePlay = false }) => {
  const src = image.split(' ')[0];
  const aspectRatio = 16.0 / 9.0;
  let maxWidth = thumbnails
    .map((t) => parseInt(t.split(' ').reverse()[0], 10))
    .sort()
    .reverse()[0];
  if (!maxWidth || maxWidth === 0) {
    maxWidth = 640;
  }
  const maxHeight = (maxWidth * 1.0) / aspectRatio;
  const style = {
    maxWidth: '100%',
    height: 'auto',
    aspectRatio,
  };
  return (
    <div id={`v-${videoId}`} data-video-id={videoId} className={`${styles['video-link']} pr-md-3${removeBars ? ' remove_bars' : ''}`}>
      <a
        id={`l-${videoId}`}
        data-video-id={videoId}
        data-event-category="video click"
        data-event-label={`video - ${title}`}
        className="youtube_videobox_link"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setModalShowing(true);
        }}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img loading="lazy" src={src} srcSet={thumbnails.join(',')} width={maxWidth} height={maxHeight} style={style} alt={title} title={title} />
        {ready && (
          <span
            className={tw(
              'flex h-[62px] w-[62px] items-center justify-center md:h-[80px] md:w-[80px] lg:h-[86px] lg:w-[86px]',
              !whitePlay
                ? 'absolute-center'
                : 'absolute bottom-[13px] right-[11px] md:bottom-[30px] md:right-[30px] lg:bottom-[30px] lg:right-[52px] desktop-xl:right-[52px]',
              redPlay ? styles['red-play'] : ''
            )}
          >
            {bluePlay && <div className="absolute inset-0 top-[1px] z-[-99] m-auto h-[98%] w-[98%] rounded-full backdrop-blur-[7px] lg:backdrop-blur-[15px]" />}
            {(() => {
              let PlayButton = null;

              if (bluePlay) {
                PlayButton = <BluePlayButton className="h-full w-full" />;
              } else if (whitePlay) {
                PlayButton = <WhitePlayButton className="h-full w-full" />;
              } else {
                PlayButton = <YouTubePlayButton className="h-full w-full" />;
              }

              return PlayButton;
            })()}
          </span>
        )}
      </a>
    </div>
  );
};

const VideoModal = ({
  videoId,
  // title, image, thumbnails,
  // ready,
  modalShowing,
  setModalShowing,
}) => {
  // const playerRef = useRef(null);

  // const onShow = () => {
  //   log.debug('player ready: %o', { ready, videoId });
  //   playerRef.current = new window.YT.Player(`c-${videoId}`, {
  //     videoId,
  //     playerVars: {
  //       playsinline: 1,
  //       enablejsapi: 1,
  //     },
  //     events: {
  //       onReady: onPlayerReady,
  //       onStateChange: onPlayerStateChange,
  //     },
  //   });
  // };

  const onClose = () => {
    setModalShowing(false);
    log.debug('stopping');
  };

  return (
    <Gallery
      isOpen={modalShowing}
      onClose={onClose}
      media={[
        {
          type: 'youtube',
          videoId,
        },
      ]}
      classes={{ panel: 'max-w-7xl' }}
    />
  );
};

let counter = 0;

const N4Youtube = ({ blok }) => {
  const [ready, setReady] = useState(false);
  const [modalShowing, setModalShowing] = useState(false);
  log.silly('blok: %o', blok);

  const url = blok.video_url?.youtube_raw;

  const youtubeUrl = url ? normalizeUrl({ url: blok.video_url?.youtube_raw, origin: 'n4youtube' }) : null;

  const match = youtubeUrl ? youtubeUrl.match(/(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^?&"'>]+)/) : null;
  const videoId = match ? match[5] : null;

  useEffect(() => {
    if (!videoId) {
      return () => null;
    }

    const target = document.getElementById(`v-${videoId}`);
    if (!target) {
      return () => null;
    }
    const fn = (entries, observer) => {
      if (document.getElementById('youtube-iframe-api')) {
        observer.unobserve(target);
        return;
      }
      if (entries.some((entry) => entry.isIntersecting)) {
        loadScript();
        observer.unobserve(target);
      }
    };
    const observer = new IntersectionObserver(fn);
    observer.observe(target);
    if (window.youtubeReady) {
      setReady(true);
    } else {
      window.onYouTubeIframeAPIReady = () => {
        window.youtubeReady = true;
        log.debug('youtube ready');
        setReady(true);
      };
      // In case there are multiple videoes, our ready callback is only
      // called once so we need to keep checking for a bit.
      const checkFn = () => {
        if (window.youtubeReady) {
          setReady(true);
        } else {
          counter += 1;
          if (counter < 10) {
            setTimeout(checkFn, 1000);
          } else {
            // TODO: need to do better than this
            // We don't start the clock until they scroll over one
            // so we can't use a timer. Need some way of notifying
            // all videos that it's ready. Just assuming it is for now.
            setReady(true);
          }
        }
      };
      setTimeout(checkFn, 2000);
    }
    return () => {
      observer.unobserve(target);
    };
  }, [videoId]);

  if (!videoId) {
    return null;
  }
  const components = [];
  // if (blok.caption) {
  //   components.push(
  //     <h2 key="title" id={`a-${kebabCase(blok.caption)}`}>
  //       {blok.caption}
  //     </h2>
  //   );
  // }
  if (blok.description) {
    components.push(
      <div className="markdown-render" key="description">
        <ReactMarkdown className="n4-markdown-blok">{blok.description}</ReactMarkdown>
      </div>
    );
  }
  const { title } = blok.video_url.youtube_oembed.response.items[0].snippet.localized;
  const thumbnails = parseThumbnails(blok);
  const defaultImage = thumbnails[0];
  components.push(
    <div key="video" className={styles.video}>
      <VideoLink
        key="video"
        videoId={videoId}
        title={title}
        removeBars={!!blok.remove_bars}
        redPlay
        image={defaultImage}
        thumbnails={thumbnails}
        setModalShowing={setModalShowing}
        ready={ready}
        bluePlay={blok.bluePlay || blok.button_style === 'blue'}
        whitePlay={blok.whitePlay || blok.button_style === 'white'}
        defaultPlay={blok.defaultPlay || blok.button_style === ''}
      />
    </div>,
    <VideoModal
      key="video-modal"
      videoId={videoId}
      title={title}
      image={defaultImage}
      thumbnails={thumbnails}
      modalShowing={modalShowing}
      setModalShowing={setModalShowing}
      ready={ready}
    />
  );
  if (blok.closed_captions || blok.caption) {
    const text = [];
    if (blok.caption) {
      text.push(blok.caption);
      if (blok.closed_captions) {
        text.push(' ');
      }
    }
    if (blok.closed_captions) {
      text.push('Closed captions are available on this video. If you are using a mobile phone, please enable captions clicking on the gear icon.');
    }
    components.push(
      <p key="captions" className={styles['captions-bottom']}>
        <span>{text.join('')}</span>
      </p>
    );
  }
  return (
    <section {...storyblokEditable(blok)} className={cx('ht-youtube', styles['n4-youtube'])}>
      {components}
    </section>
  );
};

export default N4Youtube;
