import type { HearingAid, Level, Model, Offer, ReleasePrices } from 'types/release';

export const isDevelopment = (process.env.NEXT_PUBLIC_ENV || process.env.SERVER_ENV || 'development') === 'development';

export const utmize = (url: string, content: string, term: string) => {
  const newUrl = new URL(url);
  newUrl.searchParams.set('utm_campaign', 'lead-gen');
  newUrl.searchParams.set('utm_medium', 'button');
  newUrl.searchParams.set('utm_content', content);
  newUrl.searchParams.set('utm_term', term);
  return newUrl.toString();
};

export const scrollToAnchorWithOffset = (id: string, offset: number) => {
  const target = document.getElementById(id) as HTMLElement;
  const targetOffsetTop = target?.offsetTop || 0;
  window.scrollTo({ top: targetOffsetTop + offset, behavior: 'smooth' });
};

const priceSort = (o1: Offer, o2: Offer) => {
  const a = o1.price,
    b = o2.price;
  const a1 = a.replace(/[^0-9]/g, ''); // remote $ and comma
  const b1 = b.replace(/[^0-9]/g, '');
  const af1 = parseFloat(a1);
  const bf1 = parseFloat(b1);
  const sign = Math.sign(af1 - bf1);
  return sign;
};

export const flattenAndSortOffers = (prices: ReleasePrices): Offer[] =>
  Object.values<Offer[]>(prices?.offers || {})
    .flat()
    .sort(priceSort);

interface OneTruePriceProps {
  prices?: ReleasePrices;
  model?: Model;
  level?: Level;
  hearingAid?: HearingAid;
}

export const getOneTrueOffer = ({ prices, model, level, hearingAid }: OneTruePriceProps): Offer | null => {
  if (!prices) {
    return null;
  }
  const offers = flattenAndSortOffers(prices);
  let filtered;
  if (hearingAid) {
    filtered = offers.filter((o) => o.hearingAidIds.includes(hearingAid.id));
  } else if (level) {
    filtered = prices.offers[level.id];
  } else if (model) {
    filtered = offers.filter((o) => o.modelIds.includes(model.id));
  } else {
    filtered = offers;
  }
  if (filtered.length === 1) {
    return filtered[0];
  }
  return null;
};

export const getMatchingOffers = ({ prices, model, level, hearingAid }: OneTruePriceProps): Offer[] => {
  if (!prices) {
    return [];
  }
  const offers = flattenAndSortOffers(prices);
  let filtered;
  if (hearingAid) {
    filtered = offers.filter((o) => o.hearingAidIds.includes(hearingAid.id));
  } else if (level) {
    filtered = prices.offers[level.id];
  } else if (model) {
    filtered = offers.filter((o) => o.modelIds.includes(model.id));
  } else {
    filtered = offers;
  }
  return filtered;
};
