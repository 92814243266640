import { getCookie, setCookie } from './cookies';

const loadUserWay = () => {
  /* eslint-disable */
  (function (w, i, d, g, e, t, s) {
    w[d] = w[d] || [];
    t = i.createElement(g);
    t.async = 1;
    t.src = e;
    s = i.getElementsByTagName(g)[0];
    s.parentNode.insertBefore(t, s);
    t.dataset.account = 'yj0LD5HuLt';
  })(window, document, 'UserWay', 'script', 'https://cdn.userway.org/widget.js');
  /* eslint-enable */
};

const init = () => {
  // pre-load it
  const uwc = getCookie({ name: 'userWay' });
  if (document.querySelector('.uw') && uwc) {
    setTimeout(loadUserWay, 2000);
  }
  document.addEventListener('click', (e) => {
    if (e.target.classList.contains('uw')) {
      e.preventDefault();
      if (typeof window.UserWay === 'undefined' || !window.UserWay.widgetToggle) {
        setCookie({ name: 'userWay', value: 'show', days: 7 });
        loadUserWay();
        document.addEventListener('userway:init_completed', (event) => {
          const instance = event.detail.userWayInstance;
          instance.widgetOpen();
        });
      } else {
        window.UserWay.widgetToggle();
      }
    }
  });
};

export default init;
