import React, { useEffect, useState } from 'react';

import { useAnimation, motion } from 'framer-motion';
import NextLink from 'next/link';
import { useInView } from 'react-intersection-observer';

import Auth from 'components/common-n4/auth';
import { Button } from 'components/common-n4/button';
import ImageWrapper from 'components/common-n4/image';
import { Link } from 'components/common-n4/link';
import { ShareButtons } from 'components/common-n4/share-buttons';
import fonts from 'components/layout/fonts';
import SvgIcon from 'components/ui/svg_icon';
import { useApiData } from 'hooks/use_api_data';
import { useSession } from 'hooks/use_session';
import logger from 'lib/logger';
import { useStoryblok } from 'lib/storyblok';
import { cx, normalizeUrl, refreshCachedPage, tw } from 'lib/utils';

import styles from './footer.module.scss';

const log = logger({ category: 'n4/Footer' });

const CONNECT_LINKS = [
  {
    type: 'facebook',
    link: 'https://facebook.com/hearingtracker',
  },
  {
    type: 'instagram',
    link: 'https://instagram.com/hearingtracker',
  },
  {
    type: 'youtube',
    link: 'https://www.youtube.com/@hearingtracker',
  },
  {
    type: 'linkedin',
    link: 'https://www.linkedin.com/company/hearing-tracker',
  },
  {
    type: 'twitter',
    link: 'https://twitter.com/hearingtracker',
  },
];

const inputId = 'emailFromPage';
const useGsc = true;

const SubscribeCTA = ({ content }) => {
  const [error, setError] = React.useState();

  async function handleSubmit(event) {
    event.preventDefault();
    if (!useGsc) {
      const formData = new FormData(event.currentTarget);
      const inputValue = formData.get(inputId)?.toString() ?? '';
      if (!inputValue || inputValue === '') {
        setError('email is required');
      }
      // TODO: do something else
    }
  }

  function handleChange() {
    setError(null);
  }

  return (
    <section className={styles.subscribe}>
      <div className={styles['image-container']}>
        <ImageWrapper image={content.subscribe_image} origin="footer-unsubscribe" />
      </div>
      <section className={styles['subscribe-details']}>
        <h5>Subscribe for the latest news and reviews from us</h5>
        <section className={styles['input-action']}>
          <form onSubmit={handleSubmit}>
            {error ? <p>{error}</p> : null}
            <section>
              <input
                id={inputId}
                name={inputId}
                onChange={handleChange}
                placeholder="Enter your email"
                data-gsc="275698"
                data-gsc-field={inputId}
                data-gsc-optional="true"
              />
              {/* error ? <p className="mb-1">{error}</p> : null */}
              <Button.Icon icon="arrow-right-regular" className="gsc" data-gsc="275698" data-gsc-field={inputId} />
            </section>
          </form>
        </section>
        <span>By subscribing you agree with our Privacy Policy.</span>
      </section>
    </section>
  );
};

const Footer = ({ title, isHomepage }) => {
  const { user, signout } = useSession();
  const { footer } = useApiData() || {};
  const { content, config } = footer || {};
  const [showRefresh, setShowRefresh] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const { story } = useStoryblok() || {};

  log.debug('config: %o, blok: %o', config, content);

  const refreshPage = (e) => {
    e.preventDefault();
    setRefreshing(true);
    refreshCachedPage();
  };

  const footerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    // triggers visible variant on scroll into view
    if (inView) {
      controls.start('visible');
    }

    // adds refresh button for admins
    // setShowRefresh(story && (/staging.hearingtracker.com/.test(window.location.hostname) || (user && user.admin)));
    setShowRefresh(story && user && user.admin);
  }, [controls, inView, user, story]);

  if (!(content && config)) {
    return null;
  }
  const data = JSON.parse(config);

  // https://app.storyblok.com/#/me/spaces/45415/stories/0/0/540308192

  return (
    <section className={cx(styles['n4-footer-container'], fonts.hankenGrotesk.className, isHomepage ? styles['home-footer-container'] : '')}>
      <Auth isOpen={isAuthOpen} setIsOpen={setIsAuthOpen} />
      <motion.section ref={ref} className={styles['n4-footer']} variants={footerVariants} initial="hidden" animate={controls} transition={{ duration: 0.75 }}>
        <SubscribeCTA content={content} />
        <section className={styles.container}>
          <section className={styles.top}>
            <section className={styles.copy}>
              <h3>{data?.caption1}</h3>
              <h3 className={styles['second-caption']}>{data?.caption2}</h3>
            </section>
            <section className={styles.links}>
              {data.columns?.map(({ title: columnTitle, links }) => (
                <section key={columnTitle} className={styles.column}>
                  <h4>{columnTitle}</h4>
                  <ul>
                    {links.map(({ text, url }) => (
                      <li key={text}>
                        <Link variant="dark" href={normalizeUrl({ url, origin: 'footer' })} rel="">
                          {text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </section>
              ))}
            </section>
          </section>
          <section className={styles.bottom}>
            <NextLink prefetch={false} href="/" className={styles.logo} alt="HearingTracker Logo" />
            <ShareButtons.PrimaryDark links={CONNECT_LINKS} shouldOpenNewTab title={title} className={isHomepage ? styles.homeShareButtons : ''} />
          </section>
        </section>
        <section className={`${styles['footer-bottom']} -mt-2 sm:mt-0 md:mt-0 lg:-mt-2`}>
          <ul className={`${styles['terms-links']} order-2 md:order-1`}>
            <li>
              <Link variant="secondary" href="/terms">
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link variant="secondary" href="/privacy">
                Privacy Policy
              </Link>
            </li>
            <li>
              {user && (
                <button
                  type="button"
                  onClick={signout}
                  className="w-fit text-[0.875rem] leading-[21px] text-navy hover:underline active:text-lapis active:no-underline"
                >
                  Sign Out {user.email}
                </button>
              )}
              {!user && (
                <button
                  type="button"
                  onClick={() => setIsAuthOpen(true)}
                  className="w-fit text-[0.875rem] leading-[21px] text-navy hover:underline active:text-lapis active:no-underline"
                >
                  Sign In
                </button>
              )}
            </li>
          </ul>
          <section className={`${styles.copyright} order-1 md:order-2`}>© {new Date().getFullYear()} HearingTracker. All rights reserved.</section>
        </section>
        {showRefresh && story && (
          <div className={tw('refresh-page flex items-center gap-5')}>
            <NextLink
              prefetch={false}
              href="/"
              onClick={refreshPage}
              title="Re-validate this page, i.e. clear the cache. Admin only."
              className={tw('align-middle')}
            >
              <SvgIcon icon="sync-alt" className="active-admin" spin={refreshing} />
            </NextLink>
            <NextLink target="_blank" href={`https://app.storyblok.com/#/me/spaces/45415/stories/0/0/${story.id}`} title="Navigate to story in visual editor">
              Storyblok
            </NextLink>
          </div>
        )}
      </motion.section>
    </section>
  );
};

export default Footer;
