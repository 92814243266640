import React, { useState } from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { SUB_SCORE_KEYS, SubScore, useSubScore } from 'components/bloks/n4/product/expert-review';
import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import ImageWrapper from 'components/common-n4/image';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import PriceButton from 'components/widgets/price-button';
import { tw } from 'lib/utils';
import type { HearingAid, Model, Release } from 'types/release';

import styles from './product-info.module.scss';

interface SubScoreProps {
  subScoreKey: (typeof SUB_SCORE_KEYS)[number] | undefined;
  score: number;
  comment?: string;
  summary?: string;
  comparison: number;
}

interface ScoreCardProps {
  subScoresWithScores: (SubScoreProps | null)[];
}

interface ReviewsTabProps {
  className?: {
    trigger?: ClassNameValue;
  };
  release: Release;
  hearingAid?: HearingAid;
  model?: Model;
  useModel: boolean;
  onTabClick: (tab: number) => void;
}

const ScoreCard: React.FC<ScoreCardProps> = ({ subScoresWithScores }) => {
  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  return (
    <div className="">
      <h3 className="text-xl font-medium leading-[120%] tracking-tight text-navy">Expert Review</h3>
      <ul className="mt-4 flex flex-col gap-4">
        {subScoresWithScores
          .filter((subScore): subScore is SubScoreProps => subScore !== null)
          .map((subScore: SubScoreProps, index: number) => (
            <SubScore
              key={subScore?.subScoreKey}
              subScoreKey={subScore?.subScoreKey}
              subScore={subScore}
              className={!isViewMore && index > 3 && 'hidden lg:block'}
              buttonClassName="lg:py-[16px]"
              subScoreKeyClassName="lg:text-[18px]"
              commentClassName="lg:text-[16px] lg:leading-[130%] lg:-tracking-[0.48px]"
            />
          ))}
      </ul>
      <ViewMoreButton
        isViewMore={isViewMore}
        negativeStateText="View more scores"
        positiveStateText="View less scores"
        onViewMoreButtonClick={() => setIsViewMore((currentIsViewMore) => !currentIsViewMore)}
        className="mt-8 lg:hidden"
      />
    </div>
  );
};

export const ReviewsTab: React.FC<ReviewsTabProps> = ({ model, release, hearingAid, useModel, onTabClick }) => {
  const scores = (hearingAid && hearingAid.scores) || (useModel && model?.scores) || release.scores;
  const subScoresWithScores = useSubScore(scores);

  return (
    <>
      <div className={tw('relative mt-[32px] flex w-full flex-col border-[#D4D4D4] pb-[0.5rem] md:w-[200px] md:pb-[24px] lg:w-[261px]')}>
        {scores?.total_score && (
          <CircleScoreFluid
            size="md"
            progress={((scores?.total_score || 0) / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
            amount={scores?.total_score || 0}
            className="absolute left-3 top-3"
            origin="directory/product-info/product-info-popup"
          />
        )}
        <ImageWrapper
          image={(useModel && model?.image) || release.image}
          origin="featured-articles"
          imgProps={{
            className: 'border border-navy/10 rounded-[20px] h-[221px] object-contain bg-white py-6 px-6',
          }}
        />
        <div className="mt-6">
          <PriceButton
            release={release}
            model={model}
            prices={release.prices}
            noGeo
            origin="directory/product-info-popup"
            position="product-info-popup"
            onViewPrices={() => onTabClick(1)}
          />
        </div>
      </div>
      <div className={tw('grid gap-y-[32px] overflow-y-scroll pb-[24px] md:w-[400px] lg:w-[440px] lg:pt-[32px]', styles['ht-product-info-scroll'])}>
        {subScoresWithScores.length ? <ScoreCard subScoresWithScores={subScoresWithScores} /> : null}
      </div>
    </>
  );
};
