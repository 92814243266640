import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import ImageWrapper from 'components/common-n4/image';
import { Skeleton } from 'components/common-n4/skeleton';
import SvgIcon from 'components/ui/svg_icon';
import { useIsMediumOrMobileView } from 'hooks/use_is_mobile_view';
import logger from 'lib/logger';
import { cx, formatDate, normalizeUrl } from 'lib/utils';

import styles from '../nav/custom_link.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'nav/CustomLink' });

/* eslint-disable-next-line no-unused-vars */
const CustomLink = ({ blok }) => {
  log.debug('blok: %o', blok);

  const imagePosition = blok?.image_position?.replace(';', '');
  const [hasImageLoaded, setHasImageLoaded] = React.useState(false);
  const isCTA = blok.call_to_action;
  const [publishedDate, setPublishedDate] = React.useState();
  const link = blok.link.url || blok.link.cached_url;
  const { image, large } = blok;
  const isMediumOrMobileView = useIsMediumOrMobileView();

  // if there is an image ignore, but if there is no image create
  // a fake latency for consistent loading user experience.
  React.useEffect(() => {
    if (image?.filename) {
      return undefined;
    }

    const timer = setTimeout(() => {
      setHasImageLoaded(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [image?.filename]);

  React.useEffect(() => {
    if (blok.published_date) {
      setPublishedDate(formatDate(blok.published_date));
    }
  }, [blok.published_date]);

  return (
    <div className={styles.wrapper} {...storyblokEditable(blok)} style={{ display: 'flex', flexDirection: 'column' }}>
      <button
        type="button"
        style={{
          fontSize: '.9rem',
          background: '#40c6c4',
          color: 'white',
          textAlign: 'left',
          padding: 2,
          paddingLeft: 4,
          maxHeight: '1.5rem',
          display: 'flex',
        }}
      >
        <SvgIcon icon="grip-dots-regular" /> {blok.title}
      </button>
      <NextLink href={normalizeUrl({ url: link, origin: 'nav-expanded/custom_link' })} rel="nofollow">
        {isCTA ? (
          <section className={styles['custom--cta']}>
            {large && image ? (
              <section className={styles['custom--cta--row']}>
                <figure className={styles.image}>
                  <ImageWrapper
                    image={image}
                    imgProps={{
                      alt: image.alt || blok.title,
                      loading: 'eager',
                      style: { objectPosition: imagePosition || 'center' },
                    }}
                    onLoaded={() => setTimeout(() => setHasImageLoaded(true), 0)}
                    origin="nav-custom-link-row"
                  />
                  {/* investigated for awhile, not sure why style for all skeletons inside of custom link
                  are not being applied in scss file so applying directly as a last resort */}
                  {image?.filename && !hasImageLoaded ? (
                    <div className={styles.skeleton} style={{ position: 'absolute', display: 'flex', width: '100%', height: '100%' }}>
                      <Skeleton />
                    </div>
                  ) : null}
                </figure>
                {hasImageLoaded ? (
                  <section className={styles.content}>
                    <section className={styles['content-data']}>
                      <section className={styles.about}>
                        <span className={styles.title}>{blok.title}</span>
                        <p className={styles.description}>{blok.description}</p>
                      </section>
                      <section className={styles.action}>
                        <span>{blok.link_text}</span>
                        <section className={styles.offer}>
                          <SvgIcon icon="arrow-right-regular" />
                        </section>
                      </section>
                    </section>
                  </section>
                ) : null}
              </section>
            ) : (
              <section className={styles['custom--cta--column']}>
                <section className={styles.container}>
                  <figure className={styles.image}>
                    <div style={{ display: hasImageLoaded ? 'block' : 'none' }}>
                      <ImageWrapper
                        image={image}
                        imgProps={{
                          alt: image.alt || blok.title,
                          loading: 'eager',
                          style: { objectPosition: image.position || 'center' },
                        }}
                        onLoaded={() => setTimeout(() => setHasImageLoaded(true), 0)}
                        origin="nav-custom-link-column"
                      />
                    </div>
                    {/* investigated for awhile, not sure why style for all skeletons inside of custom link
                  are not being applied in scss file so applying directly as a last resort */}
                    {image?.filename && !hasImageLoaded ? (
                      <div className={styles.skeleton} style={{ position: 'absolute', display: 'flex', width: '100%', height: '100%' }}>
                        <Skeleton />
                      </div>
                    ) : null}
                  </figure>
                  {hasImageLoaded ? (
                    <section className={styles.content}>
                      <section className={styles.about}>
                        <span className={styles.title}>{blok.title}</span>
                        <p className={styles.description}>{blok.description}</p>
                      </section>
                      <section className={styles.action}>
                        <span>{blok.link_text}</span>
                        <section className={styles.offer}>
                          <SvgIcon icon="arrow-right-regular" />
                        </section>
                      </section>
                    </section>
                  ) : null}
                </section>
              </section>
            )}
          </section>
        ) : null}

        {!isCTA ? (
          <section className={styles['custom--non-cta']}>
            {large && !isMediumOrMobileView ? (
              <section className={styles['custom--non-cta--row']}>
                <figure className={styles.image}>
                  {image?.filename ? (
                    <ImageWrapper
                      image={image}
                      imgProps={{ alt: image.alt || blok.title, loading: 'eager' }}
                      onLoaded={() => setTimeout(() => setHasImageLoaded(true), 0)}
                      origin="nav-custom-link-non-cta-row"
                    />
                  ) : null}

                  {/* investigated for awhile, not sure why style for all skeletons inside of custom link
                  are not being applied in scss file so applying directly as a last resort */}
                  {image?.filename && !hasImageLoaded ? (
                    <div className={styles.skeleton} style={{ position: 'absolute', display: 'flex', width: '100%', height: '100%' }}>
                      <Skeleton />
                    </div>
                  ) : null}
                  {hasImageLoaded ? (
                    <div className={styles.icon}>
                      <SvgIcon className={styles.icon} icon="arrow-right-regular" />
                    </div>
                  ) : null}
                </figure>
                <section className={styles.details}>
                  <h4>{blok.title}</h4>
                  {blok.description ? <p>{blok.description}</p> : <p>Description</p>}
                </section>
              </section>
            ) : (
              <section className={styles['custom--non-cta--column']}>
                <figure>
                  {image?.filename ? (
                    <div>
                      <ImageWrapper
                        image={image}
                        imgPros={{ alt: image.alt || blok.title, loading: 'eager' }}
                        sizingProps={{ maxWidth: 320 }}
                        onLoaded={() => setTimeout(() => setHasImageLoaded(true), 0)}
                        origin="nav-custom-link-non-cta-column"
                      />
                    </div>
                  ) : null}
                  {!image?.filename ? <div className={styles.placeholder} /> : null}
                  {/* investigated for awhile, not sure why style for all skeletons inside of custom link
                  are not being applied in scss file so applying directly as a last resort */}
                  {!hasImageLoaded ? (
                    <div className={styles.skeleton} style={{ position: 'absolute', display: 'flex', width: '100%', height: '100%' }}>
                      <Skeleton />
                    </div>
                  ) : null}
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {hasImageLoaded && !isMediumOrMobileView ? (
                    publishedDate ? (
                      <div className={styles.icon}>
                        <SvgIcon className={styles.icon} icon="arrow-right-regular" />
                      </div>
                    ) : (
                      <div className={styles.vs}>
                        <span>vs</span>
                      </div>
                    )
                  ) : null}
                </figure>
                <section className={cx(styles.details, !publishedDate && styles['details--gap'])}>
                  <h4>{blok.title}</h4>
                  {publishedDate ? (
                    <div className={styles.date}>{publishedDate}</div>
                  ) : (
                    <p className={styles.description}>{blok.description || 'Description'}</p>
                  )}
                </section>
              </section>
            )}
          </section>
        ) : null}
      </NextLink>
    </div>
  );
};

export default CustomLink;
