import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { tw } from 'lib/utils';

type GetClassNameByIsInViewStatus = (isInView: boolean) => string;

interface FlexiClassNameProps {
  containerClassName?: ClassNameValue;
  itemClassName?: ClassNameValue | GetClassNameByIsInViewStatus;
  itemButtonClassName?: ClassNameValue | GetClassNameByIsInViewStatus;
  activeClassName?: string;
  inactiveClassName?: string;
  downOffset?: number;
  upOffset?: number;
}

interface TOCItemProps extends FlexiClassNameProps {
  sectionId: string;
  title: string;
  currentTarget: CurrentTarget;
  setCurrentTarget: React.Dispatch<React.SetStateAction<CurrentTarget | undefined>>;
  downOffset?: number;
  upOffset?: number;
}

const TOCItem: React.FC<TOCItemProps> = ({
  sectionId,
  title,
  currentTarget,
  setCurrentTarget,
  itemClassName,
  itemButtonClassName,
  activeClassName,
  inactiveClassName,
  downOffset = -20,
  upOffset = -70,
}) => {
  const itemRef = useRef<HTMLLIElement>(null);
  const [isInView, setIsInView] = useState(false);
  const prevScrollY = useRef<number>();
  const activeClassNames = useMemo(() => activeClassName?.split(' ') || [], [activeClassName]);
  const inactiveClassNames = useMemo(() => inactiveClassName?.split(' ') || [], [inactiveClassName]);

  React.useEffect(() => {
    prevScrollY.current = window.scrollY;
  }, []);

  const onClick = useCallback(() => {
    const target = document.getElementById(sectionId) as HTMLElement;
    const currentScrollY = window.scrollY;
    const targetOffsetTop = target?.offsetTop || 0;
    const scrollDirection = targetOffsetTop > currentScrollY ? 'down' : 'up';
    const offset = scrollDirection === 'down' ? downOffset : upOffset;
    window.scrollTo({ top: targetOffsetTop + offset, behavior: 'smooth' });
    /* Playing with getting back button to go back to toc, but not working
    if (window.location.hash === '') {
      window.history.replaceState({}, '', '#toc');
    }
    setTimeout(() => {
      window.history.pushState({}, '', `#${sectionId}`);
    }, 1000);
    */
  }, [sectionId, downOffset, upOffset]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollDirection = currentScrollY > (prevScrollY.current || 0) ? 'down' : 'up';
      prevScrollY.current = currentScrollY;

      const target = document.getElementById(sectionId) as HTMLElement;
      const targetTop = target?.getBoundingClientRect().top;
      const isNearTop = targetTop <= window.innerHeight * 0.2; // Adjust this value as needed
      const isAboveHalfway = targetTop < window.innerHeight / 2;

      if (scrollDirection === 'up' && isAboveHalfway) {
        // Highlight the previous section when scrolling up
        setCurrentTarget((prevTarget) => {
          const prevSectionId = itemRef.current?.previousElementSibling?.id || sectionId;
          return prevSectionId !== prevTarget?.target ? { target: prevSectionId } : prevTarget;
        });
      } else if (scrollDirection === 'down' && isNearTop) {
        // Highlight the current section when it's near the top while scrolling down
        setCurrentTarget({ target: sectionId });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sectionId, setCurrentTarget]);

  useEffect(() => {
    setIsInView(currentTarget.target === sectionId);
  }, [currentTarget, sectionId]);

  useEffect(() => {
    if (isInView) {
      itemRef.current?.classList.add(...activeClassNames);
      itemRef.current?.classList.remove(...inactiveClassNames);
    } else {
      itemRef.current?.classList.add(...inactiveClassNames);
      itemRef.current?.classList.remove(...activeClassNames);
    }
  }, [activeClassNames, inactiveClassNames, isInView]);

  return (
    <li ref={itemRef} className={typeof itemClassName === 'function' ? itemClassName(isInView) : tw(itemClassName)}>
      <button className={typeof itemButtonClassName === 'function' ? itemButtonClassName(isInView) : tw(itemButtonClassName)} onClick={onClick}>
        {title}
      </button>
    </li>
  );
};

export interface TableOfContentsItem {
  sectionId: string;
  title: string;
}

interface TableOfContentsProps extends FlexiClassNameProps {
  toc: TableOfContentsItem[];
}

interface CurrentTarget {
  target?: string;
}

export const TableOfContents: React.FC<TableOfContentsProps> = ({
  toc,
  containerClassName,
  itemClassName,
  itemButtonClassName,
  activeClassName,
  inactiveClassName,
  downOffset = -20,
  upOffset = -70,
}) => {
  const [currentTarget, setCurrentTarget] = useState<CurrentTarget | undefined>({ target: undefined });

  return (
    <ul id="toc" className={tw(containerClassName)}>
      {toc.map(({ sectionId, title }: TableOfContentsItem) => (
        <TOCItem
          key={sectionId}
          itemClassName={itemClassName}
          itemButtonClassName={itemButtonClassName}
          sectionId={sectionId}
          title={title}
          currentTarget={currentTarget!}
          setCurrentTarget={setCurrentTarget}
          activeClassName={activeClassName}
          inactiveClassName={inactiveClassName}
          downOffset={downOffset}
          upOffset={upOffset}
        />
      ))}
    </ul>
  );
};
