import * as React from 'react';

import { usePathname } from 'next/navigation';

import Footer from 'components/bloks/n4/footer';
// import checkFonts from 'lib/fonts';
import logger from 'lib/logger';
import setupSiteControl from 'lib/site_control';
import { setupTracking } from 'lib/tracking';
import setupUserWay from 'lib/user_way';
import { tw } from 'lib/utils';

// import Clarity from './clarity';
import fonts from './fonts';
import Meta from './meta';
import StoryblokNav from './storyblok_nav';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'components.n4layout' });

const N4Layout = ({ preview, children, ...props }) => {
  // Probably should move this to a useEffect because pathname doesn't always match on the client and server
  const pathname = usePathname();

  // Easiest way to tell if it's the home page
  const [isHomepage, setIsHomepage] = React.useState(props.story?.full_slug === '');

  const [showHeaderPopup, setShowHeaderPopup] = React.useState(!props.is404 && pathname.startsWith('/hearing-aids/compare'));

  React.useEffect(() => {
    setIsHomepage(props.story?.full_slug === '');
  }, [props.story?.full_slug]);

  React.useEffect(() => {
    setShowHeaderPopup(!props.is404 && pathname.startsWith('/hearing-aids/compare'));
  }, [props.is404, pathname]);

  React.useEffect(() => {
    setupTracking();
    setupSiteControl();
    setupUserWay();
    // checkFonts();
  }, []);

  const handleHeaderPopupClose = () => setShowHeaderPopup(false);

  return (
    <section
      className={tw(
        'ht-wrapper',
        fonts.hankenGrotesk.className,
        'bg-cream',
        // eslint-disable-next-line no-nested-ternary
        showHeaderPopup ? 'pt-[118px]' : !isHomepage ? 'pt-[80px]' : 'pt-0'
      )}
    >
      {props.story?.full_slug !== 'components/nav' && (
        <StoryblokNav isHomepage={isHomepage} showHeaderPopup={showHeaderPopup} handleHeaderPopupClose={handleHeaderPopupClose} {...props} />
      )}
      <div className="ht-n4">{children}</div>
      <Meta {...props} />
      {/* <Clarity /> */}
      {!props.hideFooter && <Footer isHomepage={isHomepage} />}
    </section>
  );
};

export default N4Layout;
