import React from 'react';

import { storyblokEditable } from '@storyblok/react';
import omit from 'lodash/omit';
import snakeCase from 'lodash/snakeCase';
import NextLink from 'next/link';
import ReactDOMServer from 'react-dom/server';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import ImageWrapper from 'components/common-n4/image';
import { PARTNER_DOMAINS } from 'lib/ht_config';
import { findBloks } from 'lib/storyblok';
import { cx } from 'lib/utils';

// Already importing this blok dynamically so don't do nested dynamics

import styles from './markdown.module.scss';

// Have to convert <sup>x</sup> to something that will come to markdown
// as a single node. Otherwise, it's spread across node and hard to
// do any substitutions.
// So <sup>x</sup> (3 nodes)=> <sup value="x"/> (1 node)
const SUP_TAG_REGEXP = /<sup>([^<]+)<\/sup>/gi;
const SUP_NODE_REGEXP = /<sup value="([^"]+)"\/>/;
// Toyed with the idea of messing with phone numbers because
// ios adds tel: to it causing a hydration error
const PHONE_REGEXP = /((\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})/;

const convertSups = (children) => {
  let newChildren = children;
  if (children instanceof Array) {
    newChildren = children.map((c, index) => {
      if (typeof c === 'string') {
        const match = c.match(SUP_NODE_REGEXP);
        if (match) {
          const val = match[1];
          const key = `${index}-${val}`;
          return <sup key={key}>{val}</sup>;
        }
      }
      return c;
    });
  }
  return newChildren;
};

const buildAnchor = ({ url, children, ...attrs }) => {
  const href = url.toString();
  const { rel = '', ...rest } = attrs;
  let newRel = rel;
  const path = url instanceof URL ? url.pathname : href;
  if (path === 'press-releases' || path.startsWith('/press-releases/')) {
    newRel = rel.split(' ').concat(['nofollow']).join(' ').trim();
  }
  return (
    <NextLink href={url.toString()} rel={newRel || undefined} {...rest} prefetch={false}>
      {convertSups(children)}
    </NextLink>
  );
};

// eslint-disable-next-line no-unused-vars
const components = ({ decorateLinks = true, debug }) => ({
  a: (props) => {
    // This builds an <a> adding our UTM and setting rel fields.
    const { children, href, ...rest } = omit(props, 'node');
    if (href && /^telephone.com/.test(href)) {
      const phone = href.replace(/telephone.com\//, 'tel:');
      return (
        <a href={phone} {...rest}>
          {children}
        </a>
      );
    }
    if (!href || /^#/.test(href) || /^mailto:/.test(href) || /^tel:/.test(href)) {
      return buildAnchor({ url: href, children, ...rest });
    }
    const attrs = { ...rest };
    let link = href.replace('/stories/', '/'); // because the markdown editor auto-populates these
    if (link.startsWith('//')) {
      link = `https:${link}`;
    } else if (link.startsWith('/')) {
      // normal slug with starting /
      return buildAnchor({ url: link, children, ...rest });
    } else if (link.startsWith('http://')) {
      link = link.replace(/^http:/, 'https:');
    } else if (!link.startsWith('http')) {
      // normal slug with no starting /
      return buildAnchor({ url: `/${link}`, children, ...rest });
    }
    try {
      const url = new URL(link);
      // This comes from lib/storyblok injectAds. We add some special parameters to the URL
      // so we can know to move those off the url and into the attributes.
      if (url.searchParams.get('ad-attrs')) {
        const adAttrs = JSON.parse(url.searchParams.get('ad-attrs'));
        if (adAttrs.className) {
          adAttrs.className = styles[adAttrs.className];
        }
        Object.assign(attrs, adAttrs);
        url.searchParams.delete('ad-attrs');
      }

      if (decorateLinks) {
        if (url.hostname === 'hearingtracker.com' || url.hostname === 'www.hearingtracker.com') {
          // our site is not restricted and opens in same tab
        } else if (/hearingtracker\.com/.test(url.hostname)) {
          // sister sites open in new tab, but get goodies
          attrs.target = '_blank';
        } else {
          attrs.target = '_blank';
          if (PARTNER_DOMAINS.includes(url.hostname) || PARTNER_DOMAINS.some((d) => d instanceof RegExp && d.test(url.hostname))) {
            // friends have benefits, i.e. follows
            attrs.rel = 'noopener noreferrer';
          } else {
            // everyone else get what they get and don't have a fit
            attrs.rel = 'nopopener noreferrer nofollow';
          }
          'source medium campaign content search'.split(' ').forEach((p) => url.searchParams.delete(`utm_${p}`));
          url.searchParams.append('utm_source', 'hearingtracker.com');
        }
      }

      return buildAnchor({ url, ...attrs, children });
    } catch (ex) {
      console.error('Bad url: [%s], link: [%s], %o', href, link, ex.message);
      return buildAnchor({ url: href, children, ...rest });
    }
  },
  img: (props) => {
    if (props.src && /imgix.*native/.test(props.src)) {
      // These probably come from our ad injection
      const url = new URL(props.src);
      const nativeWidth = url.searchParams.get('nativeWidth');
      const nativeHeight = url.searchParams.get('nativeHeight');
      const imgix = url.pathname.slice(1);
      const image = { imgix, nativeWidth, nativeHeight, alt: props.alt };
      const { alt } = props;
      const imgProps = { alt };
      if (/ - ad/.test(alt)) {
        imgProps.alt = alt.replace(/ - ad/, '');
        imgProps.loading = 'lazy';
      }
      return <ImageWrapper image={image} imgProps={imgProps} origin="n4-markdown" blockRobots />;
    }
    return <ImageWrapper image={omit(props, 'node')} />;
  },
  em: (props) => {
    const { children, ...rest } = omit(props, 'node');
    return <i {...rest}>{convertSups(children)}</i>;
  },
  div: (props) => {
    const { children, ...rest } = omit(props, 'node');
    return <div {...rest}>{convertSups(children)}</div>;
  },
  p: (props) => {
    const { children, ...rest } = omit(props, 'node');
    return <p {...rest}>{convertSups(children)}</p>;
  },
  li: (props) => {
    const { children, ...rest } = omit(props, 'node');
    return <li {...rest}>{convertSups(children)}</li>;
  },
  strong: (props) => {
    const { children, ...rest } = omit(props, 'node');
    return <strong {...rest}>{convertSups(children)}</strong>;
  },
  span: (props) => {
    const { children, ...rest } = omit(props, 'node');
    return <span {...rest}>{convertSups(children)}</span>;
  },
  b: (props) => {
    const { children, ...rest } = omit(props, 'node');
    return <b {...rest}>{convertSups(children)}</b>;
  },
  code: (props) => {
    const { children, ...rest } = omit(props, 'node');
    return <code {...rest}>{convertSups(children)}</code>;
  },
});

// eslint-disable-next-line no-unused-vars
export const HtMarkdown = ({ className, decorateLinks, content, debug }) => {
  const comps = React.useMemo(() => components({ decorateLinks, debug }), [decorateLinks, debug]);
  const converted = content.replace(SUP_TAG_REGEXP, '<sup value="$1"/>').replace(PHONE_REGEXP, '[$1](telephone.com/$1)');
  if (debug) {
    const html = ReactDOMServer.renderToString(
      <ReactMarkdown className={className} remarkPlugins={[remarkGfm]} components={comps}>
        {converted}
      </ReactMarkdown>
    );
    // eslint-disable-next-line no-console
    console.log(html);
  }
  return (
    <ReactMarkdown className={className} remarkPlugins={[remarkGfm]} components={comps}>
      {converted}
    </ReactMarkdown>
  );
};

const Markdown = ({ blok, story }) => {
  const articles = findBloks(story, 'n4-article');
  const sponsored = articles.some((a) => !!a.sponsor);
  const decorateLinks = !(blok.no_link_hacking || sponsored);
  const id = blok.anchor_link ? snakeCase(blok.anchor_link) : `md-${blok._uid}`;

  const debug = false; // id === 'md-06deda3c-5885-4389-b603-3c53ec0581c8';
  return (
    <section id={id} className={cx(styles.markdown, blok.small_text && styles['markdown--small'], blok.component)} {...storyblokEditable(blok)}>
      <HtMarkdown className={cx('n4-markdown-blok', styles['markdown-content'])} decorateLinks={decorateLinks} content={blok.content} debug={debug} />
    </section>
  );
};

export default Markdown;
