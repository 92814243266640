/* Regarding cookies:
    If you don't specify a domain, it will use the full current subdomain with no dot
    If you do specify a domain, it will use that with a dot prepended
    Same thing happens on client. No point in using dot on a subdomain.
    So use .hearingtracker to hit all our domains, or no domain to hit only the current one
    rewrite.cookies.set({ name: 'no-domain', value: 'nodomain' }); --> qa.hearingtracker.com
    rewrite.cookies.set({ name: 'exact-subdomain', value: 'exactsubdomain', domain: 'qa.hearingtracker.com' }); --> .qa.hearingtracker.com
    rewrite.cookies.set({ name: 'dot-subdomain', value: 'dotsubdomain', domain: '.qa.hearingtracker.com' }); --> .qa.hearingtracker.com
    rewrite.cookies.set({ name: 'dot-domain', value: 'dotdomain', domain: '.hearingtracker.com' }); --> .hearingtracker.com
    rewrite.cookies.set({ name: 'only-domain', value: 'onlydomain', domain: 'hearingtracker.com' }); --> .hearingtracker.com
*/
const getCookie = ({ name }) => {
  const cookie = document.cookie
    .split(';')
    .map((c) => c.trim())
    .find((c) => c.split('=')[0].trim() === name);
  if (cookie) {
    return cookie.split('=')[1].trim();
  }
  return null;
};

const setCookie = ({ name, value, days, exactSubdomain = false }) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; Expires=${date.toUTCString()}`;
  }
  if (window.location.hostname === 'localhost' || exactSubdomain) {
    document.cookie = `${name}=${value || ''}${expires}; Path=/;`;
  } else {
    // Note: this cookie should apply to all subdomains so www and api
    document.cookie = `${name}=${value || ''}${expires}; Path=/; Domain=.hearingtracker.com;`;
  }
};

const eraseCookie = ({ name, exactSubdomain = false }) => {
  if (window.location.hostname === 'localhost' || exactSubdomain) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  } else {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.hearingtracker.com`;
  }
};

export { getCookie, setCookie, eraseCookie };
