import React, { useCallback, useEffect } from 'react';

import { Tab, Transition } from '@headlessui/react';
import { ClassNameValue } from 'tailwind-merge';

import { Loader } from 'components/common-n4/loader';
import { Modal } from 'components/common-n4/pop-up-modal/pop-up-modal';
import fonts from 'components/layout/fonts';
import { Audio } from 'components/page/directory/release_card/audio';
import { useApiData } from 'hooks';
import IconCross from 'images/icon-cross.svg';
import { fetchApi } from 'lib/ht_api';
import { cx, tw } from 'lib/utils';
import type { HearingAid, Model, Release } from 'types/release';

import { DetailsTab } from './details-tab';
import { LabRecordingsTab } from './lab-recordings-tab';
import { PricesTab } from './prices-tab';
import { ReviewsTab } from './reviews-tab';

interface PopupTabProps extends React.PropsWithChildren {
  selected: boolean;
}

const PopupTab: React.FC<PopupTabProps> = ({ selected, children }) => {
  return <div className={tw('border-b-[3px] border-transparent pb-[17px] pt-[20px] text-[16px] leading-[130%]', selected && 'border-lapis')}>{children}</div>;
};

interface StyledTabPanelProps extends React.PropsWithChildren {
  className?: ClassNameValue;
  unmount?: boolean;
}

const StyledTabPanel: React.FC<StyledTabPanelProps> = ({ className, children, ...rest }) => {
  return (
    <Tab.Panel
      className={tw(
        'flex h-[80vh] w-[100vw] flex-col md:flex-row md:justify-between',
        'gap-y-[16px] overflow-auto px-[22px] sm:h-[587px] sm:px-[32px] md:w-[720px] md:rounded-[20px] lg:h-[587px] lg:w-[810px]',
        className
      )}
      {...rest}
    >
      {children}
    </Tab.Panel>
  );
};

interface ProductInfoPopupProps {
  className?: {
    trigger?: ClassNameValue;
  };
  releaseSlug: string;
  release?: Release;
  modelId?: number;
  hearingAidId?: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  useModel?: boolean;
  initialTab?: string;
}

interface Product {
  release: Release;
  model?: Model;
  hearingAid?: HearingAid;
}

const LoadingSkeleton = ({ isOpen, setIsOpen, selectedIndex }: { isOpen: boolean; setIsOpen: (open: boolean) => void; selectedIndex: number }) => {
  return (
    <Modal
      origin="product-info/loading"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      classes={{
        overlay: 'bg-navy/[0.80] lg:bg-navy/[0.80] md:bg-navy/[0.80] sm:bg-navy/[0.80] bg-navy/[0.80]',
        panel: 'md:rounded-[20px] rounded-t-[20px]',
      }}
    >
      <Tab.Group as="section" selectedIndex={selectedIndex} className={cx(fonts.hankenGrotesk.className, '')}>
        <div className="relative">
          <Tab.List as="ul" className="flex items-center gap-5 border-b border-gray px-8">
            <li className="-mb-[1px] whitespace-nowrap font-light lg:whitespace-normal">
              <Tab className="">{({ selected }) => <PopupTab selected={selected}>Details</PopupTab>}</Tab>
            </li>
            <li className="-mb-[1px] whitespace-nowrap font-light lg:whitespace-normal">
              <Tab className="">{({ selected }) => <PopupTab selected={selected}>Prices</PopupTab>}</Tab>
            </li>
            <li className="-mb-[1px] whitespace-nowrap font-light lg:whitespace-normal">
              <Tab className="">{({ selected }) => <PopupTab selected={selected}>Reviews</PopupTab>}</Tab>
            </li>
            <li className="-mb-[1px] whitespace-nowrap font-light lg:whitespace-normal">
              <Tab className="">{({ selected }) => <PopupTab selected={selected}>Lab Recordings</PopupTab>}</Tab>
            </li>
          </Tab.List>
          <button
            // eslint-disable-next-line max-len
            className="absolute right-[32px] top-[14px] hidden h-[32px] w-[32px] items-center justify-center rounded-full border border-navy/20 text-navy sm:flex"
            onClick={() => setIsOpen(false)}
          >
            <IconCross width="11px" height="11px" viewBox="0 0 16 16" />
          </button>
        </div>
        <Tab.Panels as="section" className="bg-cream">
          <Transition
            show={true}
            enter="transform transition duration-[400ms]"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-100 rotate-0 scale-100"
            leaveTo="opacity-0 scale-95 "
            unmount={false}
          >
            <StyledTabPanel>
              <div className="flex w-full items-center justify-center">
                <Loader size="2xl" />
              </div>
            </StyledTabPanel>
            <StyledTabPanel>
              <div className="flex w-full items-center justify-center">
                <Loader size="2xl" />
              </div>
            </StyledTabPanel>
            <StyledTabPanel>
              <div className="flex w-full items-center justify-center">
                <Loader size="2xl" />
              </div>
            </StyledTabPanel>
            <StyledTabPanel>
              <div className="flex w-full items-center justify-center">
                <Loader size="2xl" />
              </div>
            </StyledTabPanel>
          </Transition>
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
};

export const ProductInfoPopup: React.FC<ProductInfoPopupProps> = ({
  release,
  releaseSlug,
  hearingAidId,
  modelId,
  isOpen,
  setIsOpen,
  useModel,
  initialTab = 'details',
}) => {
  // dumb but don't feel like handling all the conditions we aren't using
  const [selectedIndex, setSelectedIndex] = React.useState(initialTab === 'prices' ? 1 : 0);
  const [isShowing, setIsShowing] = React.useState(true);
  const [product, setProduct] = React.useState<Product>();
  const api = useApiData();

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const fn = async () => {
      let r: Release = release || api.releases?.[releaseSlug] || api.cache?.[`release-${releaseSlug}`];
      if (!r?.full) {
        r = await fetchApi({ path: `/releases/${releaseSlug}`, fallback: undefined, origin: 'product-info-popup' });
        if (typeof r !== 'undefined') {
          api.cache[`release-${releaseSlug}`] = r;
        }
      }
      if (typeof r !== 'undefined') {
        const model = modelId ? r.models.find((m) => m.id === modelId) : r.models.find((m) => m.primary);
        const hearingAid = hearingAidId ? r.hearing_aids.find((h) => h.id === hearingAidId) : r.hearing_aids[0];
        setProduct({ release: r, model, hearingAid });
      } else {
        throw new Error(`Release ${releaseSlug} not found`);
      }
    };
    fn();
  }, [api.releases, api.cache, release, releaseSlug, modelId, hearingAidId, isOpen]);

  const onTabClick = useCallback((index: number) => {
    setIsShowing(false);
    setTimeout(() => {
      setIsShowing(true);
      setSelectedIndex(index);
    }, 100);
  }, []);

  useEffect(() => {
    setSelectedIndex(initialTab === 'prices' ? 1 : 0);
  }, [isOpen, initialTab]);

  const audio = React.useMemo(() => {
    if (product?.hearingAid?.audios) {
      return product.hearingAid.audios.find((a) => a.environment === 'quiet' && !a.tuned);
    }
    return product?.release.audio;
  }, [product?.hearingAid?.audios, product?.release?.audio]);

  if (!product) {
    return <LoadingSkeleton isOpen={isOpen} setIsOpen={setIsOpen} selectedIndex={selectedIndex} />;
  }

  const { release: rel, model, hearingAid } = product;
  const scores = hearingAid?.scores || rel.scores;

  return (
    <Modal
      origin={`product-info/${rel.slug}`}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      classes={{
        overlay: 'bg-navy/[0.80] lg:bg-navy/[0.80] md:bg-navy/[0.80] sm:bg-navy/[0.80] bg-navy/[0.80]',
        panel: 'md:rounded-[20px] rounded-t-[20px]',
      }}
    >
      <Tab.Group as="section" selectedIndex={selectedIndex} onChange={onTabClick} className={cx(fonts.hankenGrotesk.className, '')}>
        <div className="relative">
          <Tab.List as="ul" className="flex items-center gap-5 border-b border-gray px-8">
            <li className="-mb-[1px] whitespace-nowrap font-light lg:whitespace-normal">
              <Tab className="">{({ selected }) => <PopupTab selected={selected}>Details</PopupTab>}</Tab>
            </li>
            {!!rel.prices?.levels?.length && (
              <li className="-mb-[1px] whitespace-nowrap font-light lg:whitespace-normal">
                <Tab className="">{({ selected }) => <PopupTab selected={selected}>Prices</PopupTab>}</Tab>
              </li>
            )}
            {scores?.total_score && (
              <li className="-mb-[1px] whitespace-nowrap font-light lg:whitespace-normal">
                <Tab className="">{({ selected }) => <PopupTab selected={selected}>Reviews</PopupTab>}</Tab>
              </li>
            )}
            {hearingAid && (
              <li className="-mb-[1px] whitespace-nowrap font-light lg:whitespace-normal">
                <Tab className="">{({ selected }) => <PopupTab selected={selected}>Lab Recordings</PopupTab>}</Tab>
              </li>
            )}
          </Tab.List>
          <button
            // eslint-disable-next-line max-len
            className="absolute right-[32px] top-[14px] hidden h-[32px] w-[32px] items-center justify-center rounded-full border border-navy/20 text-navy sm:flex"
            onClick={() => setIsOpen(false)}
          >
            <IconCross width="11px" height="11px" viewBox="0 0 16 16" />
          </button>
        </div>
        <Tab.Panels as="section" className="bg-cream">
          <Transition
            show={isShowing}
            enter="transform transition duration-[400ms]"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-100 rotate-0 scale-100"
            leaveTo="opacity-0 scale-95 "
            unmount={false}
          >
            <StyledTabPanel>
              <DetailsTab hearingAid={hearingAid} model={model} release={rel} audio={<Audio audio={audio} />} useModel={!!useModel} onTabClick={onTabClick} />
            </StyledTabPanel>
            {!!rel.prices?.levels?.length && (
              <StyledTabPanel unmount={false} className="block">
                <PricesTab release={rel} />
              </StyledTabPanel>
            )}
            {scores?.total_score && (
              <StyledTabPanel>
                <ReviewsTab model={model} release={rel} useModel={!!useModel} hearingAid={hearingAid} onTabClick={onTabClick} />
              </StyledTabPanel>
            )}
            {hearingAid && (
              <StyledTabPanel className="block">
                <LabRecordingsTab hearingAid={hearingAid} />
              </StyledTabPanel>
            )}
          </Transition>
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
};
