import { useEffect, useRef, useState } from 'react';

import { storyblokEditable } from '@storyblok/react';

import ImageWrapper from 'components/common-n4/image';
import { useWindowDimensions } from 'hooks/use_window_dimensions';
import logger from 'lib/logger';

import styles from './slider.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'SiteReviews' });

const SiteReviews = ({ blok }) => {
  const imageTemplate = blok.imageTemplate ? blok.imageTemplate[0] : {};
  const { width: windowWidth } = useWindowDimensions();
  const [slidePosition, setSlidePosition] = useState(0);
  const [countIterations, setCountIterations] = useState(0);
  const wrapperDiv = useRef();
  const scrollDiv = useRef();
  let slideWidth;
  const scrollWidth = () => {
    if (windowWidth > 320) {
      slideWidth = 305;
    }
    if (windowWidth > 767) {
      slideWidth = 505;
    }
    return blok.siteReviews.length * slideWidth;
  };
  let leftPadding;
  if (windowWidth > 320) leftPadding = 20;
  if (windowWidth > 575) leftPadding = (windowWidth - 540) / 2 + 12;
  if (windowWidth > 767) leftPadding = (windowWidth - 720) / 2 + 12;
  if (windowWidth > 991) leftPadding = 0.55;

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 5;
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const iterNum = Math.floor((scrollWidth() - (windowWidth > 991 ? windowWidth * 0.55 : windowWidth - leftPadding)) / slideWidth);
  const lastIteration = Math.abs(iterNum - (scrollWidth() - (windowWidth > 991 ? windowWidth * 0.55 : windowWidth - leftPadding)) / slideWidth) * slideWidth;

  function nextSlide() {
    if (iterNum === 0) {
      setSlidePosition(lastIteration);
      setCountIterations(countIterations + 1);
      return;
    }
    if (countIterations < iterNum) {
      setSlidePosition(slidePosition + slideWidth);
      setCountIterations(countIterations + 1);
    } else if (countIterations === iterNum) {
      setSlidePosition(slidePosition + lastIteration);
      setCountIterations(countIterations + 1);
    } else {
      setSlidePosition(slidePosition);
    }
  }
  function prevSlide() {
    if (iterNum >= 0) {
      setSlidePosition(slidePosition - slideWidth);
      setCountIterations(countIterations - 1);
    } else if (countIterations === iterNum) {
      setSlidePosition(slidePosition - lastIteration);
      setCountIterations(countIterations - 1);
    }
  }
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      nextSlide();
    }
    if (isRightSwipe) {
      prevSlide();
    }
  };
  useEffect(() => {
    if (slidePosition < 0) {
      setSlidePosition(0);
      setCountIterations(0);
    }
  }, [slidePosition]);
  return (
    <section {...storyblokEditable(blok)} className="ht-site-reviews">
      <div className="ht-site-reviews-wrapper">
        <article>
          <div className="ht-site-reviews-layout">
            <div className="ht-site-reviews-layout-head">
              <header>
                <h2>{blok.title}</h2>
                <p className="h3">{blok.subtitle}</p>
              </header>
            </div>
            <div className="ht-site-reviews-layout-swiper">
              <div className="ht-site-reviews-layout-swiper-placeholder" />
            </div>
          </div>
        </article>
      </div>
      <div className="ht-site-reviews-slider">
        <div
          className={styles.reviewSlider}
          ref={wrapperDiv}
          onTouchStart={(e) => onTouchStart(e)}
          onTouchMove={(e) => onTouchMove(e)}
          onTouchEnd={(e) => onTouchEnd(e)}
        >
          <div
            ref={scrollDiv}
            className={styles.scrollDiv}
            style={{
              width: scrollWidth(),
              transform: `translate3d(-${slidePosition}px, 0px, 0px)`,
            }}
          >
            {blok.siteReviews.map((review) => (
              <div key={review._uid} className={styles.reviewSlideWrap}>
                <div className={`${styles.reviewCard} ht-featured-releases-card`}>
                  <div className="ht-featured-releases-card-image">
                    <figure className={styles.media}>
                      <ImageWrapper image={imageTemplate || review.image[0]} origin="site-reviews" />
                      <div className={styles.overlayText}>{review.review}</div>
                    </figure>
                  </div>
                </div>
                <div className={styles.reviewContent}>
                  <p>{review.summary}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.reviewSliderControls}>
          <div className={styles.prevSlide} onClick={prevSlide} />
          <div className={styles.nextSlide} onClick={nextSlide} />
        </div>
      </div>
    </section>
  );
};

export default SiteReviews;
