import React from 'react';

const TestError = () => {
  const [renderError, setRenderError] = React.useState(false);
  const [effectError, setEffectError] = React.useState(false);

  if (renderError && !window.renderErrorThrown) {
    window.renderErrorThrown = true;
    throw new Error('render error');
  }

  React.useEffect(() => {
    window.renderErrorThrown = false;
    window.effectErrorThrown = false;
  }, []);

  React.useEffect(() => {
    if (effectError && !window.effectErrorThrown) {
      window.renderErrorThrown = true;
      throw new Error('effect error');
    }
  }, [effectError]);

  const makeError = () => {
    throw new Error('event handler error');
  };

  return (
    <>
      <button className="w-[200px] rounded-lg border bg-navy p-2 text-white" type="button" onClick={makeError}>
        Event Handler Error
      </button>
      <button className="w-[200px] rounded-lg border bg-navy p-2 text-white" type="button" onClick={() => setRenderError(true)}>
        Render Error
      </button>
      <button className="w-[200px] rounded-lg border bg-navy p-2 text-white" type="button" onClick={() => setEffectError(true)}>
        Effect Error
      </button>
    </>
  );
};

export default TestError;
