import type { SbBlokData } from '@storyblok/react';

import { tw } from 'lib/utils';

import FAQ from '../faq';

interface FAQItem {
  question: string;
  answer: string;
}

export interface FAQData {
  questions: FAQItem[];
}

interface Blok extends SbBlokData {
  faqData: FAQData;
}

const ContactFAQ: React.FC<Blok> = ({ faqData }) => {
  return (
    <section
      className={tw(
        'flex w-full flex-col justify-start p-[32px_16px_80px_16px] md:p-[32px_48px_96px_48px] desktop-xl:m-auto desktop-xl:max-w-[1440px]',
        'mb-0 mt-16 sm:mt-10'
      )}
    >
      <div className="flex flex-col gap-4 md:gap-6">
        <div className="text-[32px] font-semibold leading-[38.4px] tracking-[-0.96px] text-navy md:text-[40px] md:leading-[40px] md:tracking-[-1.2px]">
          FAQs
        </div>
        <div className="text-[16px] font-normal leading-[20.8px] tracking-[-0.48px] text-navy/80">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
        </div>
      </div>
      <FAQ blok={faqData} />
    </section>
  );
};

export default ContactFAQ;
