import React from 'react';

import { Panel } from './panel';

import { TableOfContents, type TableOfContentsItem } from '../table-of-contents';

interface ArticleTableOfContentsProps {
  toc: TableOfContentsItem[];
}

export const ArticleTableOfContents: React.FC<ArticleTableOfContentsProps> = ({ toc }) => {
  return (
    <Panel className="flex flex-col gap-6">
      <div className="text-xl leading-7 tracking-tight text-navy">Table of Contents</div>
      <TableOfContents
        toc={toc}
        containerClassName="flex flex-col gap-4"
        itemClassName="border-l-2 pl-4 leading-5 text-navy/40"
        itemButtonClassName="text-left text-[18px] leading-6 tracking-tight"
        activeClassName="border-lapis text-navy"
        inactiveClassName="border-transparent text-navy/40"
      />
    </Panel>
  );
};
