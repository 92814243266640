import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import ReactCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { Button } from 'components/common-n4/button';
import ImageWrapper from 'components/common-n4/image';
import SvgIcon from 'components/ui/svg_icon';
import { useApiData } from 'hooks/use_api_data';
import { useIsMobileView } from 'hooks/use_is_mobile_view';
import { useWindowDimensions, WINDOW_DIMENSION_TYPE } from 'hooks/use_window_dimensions';
import logger from 'lib/logger';
import { cx, formatDate, getWindowDimensionType, normalizeUrl } from 'lib/utils';

import styles from './article_grid.module.scss';

const anonymousAvatar = 'https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'n4/ArticleGrid' });

const toTitleCase = (str) =>
  str
    .replace(/[-/]/g, ' ') // Replace slashes and dashes with spaces
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

const Carousel = ({ articles }) => {
  const [index, setIndex] = React.useState(0);
  const activeArticle = articles?.[index];

  if (!articles?.length) {
    return null;
  }

  return (
    <section className={styles.cards}>
      <ReactCarousel
        ssr
        swipeable
        infinite
        showDots
        keyBoardControl
        autoPlay
        renderDotsOutside
        autoPlaySpeed={7500}
        beforeChange={(nextSlide) => {
          // seems to always offset slide by 2.
          setIndex(nextSlide - 2);
        }}
        responsive={{
          mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
          },
        }}
        removeArrowOnDeviceType="mobile"
        dotListClass={styles['carousel-indicators']}
      >
        {articles?.map((article) => (
          <NextLink
            prefetch={false}
            key={article.id}
            href={normalizeUrl({ url: article.slug, origin: 'article-grid#carousel' })}
            rel={article.noindex ? 'nofollow' : undefined}
          >
            <ArticleImage article={article} />
          </NextLink>
        ))}
      </ReactCarousel>
      {activeArticle ? (
        <NextLink href={normalizeUrl({ url: activeArticle.slug, origin: 'article-grid' })} rel={activeArticle.noindex ? 'nofollow' : undefined}>
          <ArticleContent article={activeArticle} isInCarousel />
        </NextLink>
      ) : null}
    </section>
  );
};

const ArticleImage = ({ article }) => {
  const isMobileView = useIsMobileView();

  if (!article.image) {
    return null; // TODO: need a placeholder?
  }

  const imageSource = article.image.includes('https:') ? article.image : `${'https:'}${article.image}`;

  return (
    <figure className={styles.image}>
      <ImageWrapper image={imageSource} imgProps={{ alt: article.title, loading: 'lazy' }} origin="article-grid" />
      {isMobileView ? null : (
        <div className={styles.icon}>
          <SvgIcon className={styles.icon} icon="arrow-right-regular" />
        </div>
      )}
      {article?.sponsor ? <span>Sponsored</span> : null}
    </figure>
  );
};

const ArticleContent = ({ article, isInCarousel }) => {
  const pathname = usePathname();
  // article meta data is from the api so author is already fleshed out here
  const { author } = article;
  const [publishedDate, setPublishedDate] = React.useState();

  React.useEffect(() => {
    if (article.republished_at || article.published_at) {
      setPublishedDate(formatDate(article.republished_at || article.published_at));
    }
  }, [article.republished_at, article.published_at]);

  if (isInCarousel) {
    return (
      <section className={cx(styles['card-content'], styles['card-content--carousel'])}>
        {author?.name && <span>{author.name}</span>}
        <h3 className="line-clamp-2">{article.title}</h3>
      </section>
    );
  }
  return (
    <div className="max-w-xl">
      <div className="flex items-center gap-x-4 text-sm">
        {publishedDate && <time dateTime={publishedDate}>{publishedDate}</time>}
        {(pathname.includes('/news') || pathname.includes('/press-releases')) && (
          <NextLink
            prefetch={false}
            href={normalizeUrl({ url: pathname, origin: 'article-grid#content' })}
            className="text-gray-600 hover:bg-gray-100 relative z-10 rounded-full bg-navy/5 px-3 py-1.5 font-medium"
          >
            {toTitleCase(pathname)}
          </NextLink>
        )}
      </div>
      <div className="group relative">
        <p className="mt-3 line-clamp-2 text-[1.75rem] font-semibold leading-[2.3rem] md:leading-[2.4rem]">{article.title}</p>
        <p className="mt-3 line-clamp-2 text-[1rem]">{article.meta_description}</p>
      </div>
      {author?.name !== 'Staff Writer' ? (
        <div className="relative mb-10 mt-5 flex items-center gap-x-4">
          {author?.slug ? (
            <NextLink prefetch={false} href={`/authors/${author.slug}`} className="shrink-0">
              <ImageWrapper
                image={author.avatar_url || anonymousAvatar}
                imgProps={{ alt: article.title, loading: 'lazy', className: 'bg-gray-100 h-10 w-10 rounded-full' }}
                sizingProps={{ fixed: false, minWidth: 40, maxWidth: 80 }}
                origin="article-grid"
              />
            </NextLink>
          ) : null}
          <div className="text-sm">
            <p className="text-gray-900 font-semibold">
              {author?.slug ? (
                <NextLink prefetch={false} href={`/authors/${author.slug}`}>
                  <span className="absolute" />
                  {author.name}
                </NextLink>
              ) : null}
            </p>
            <p className="text-gray-600">{author?.about_brief}</p>
          </div>
        </div>
      ) : (
        <div className="mb-8" /> // This will render an empty div with the class "mb-10"
      )}
    </div>
  );
};

const Article = ({ article }) => (
  <NextLink prefetch={false} href={normalizeUrl({ url: article.slug, origin: 'article-grid#article' })} rel={article.noindex ? 'nofollow' : undefined}>
    <article className={styles.card}>
      <ArticleImage article={article} />
      <ArticleContent article={article} />
    </article>
  </NextLink>
);

const ArticleList = ({ articles }) => articles?.map((article) => <Article key={article.id} article={article} />);

const MAX_ARTICLES_TO_SHOW_BASED_ON_DIMENSION = {
  [WINDOW_DIMENSION_TYPE.desktop]: 6,
  [WINDOW_DIMENSION_TYPE.laptop]: 6,
  [WINDOW_DIMENSION_TYPE.lg]: 4,
  [WINDOW_DIMENSION_TYPE.md]: 4,
};

const ArticleGrid = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  const apiData = useApiData();
  const articles = React.useRef(
    (blok.blocks?.map((articleId) => apiData.articles[articleId]) || [])
      .concat(blok.slugs?.map((slug) => apiData.articles[slug]) || [])
      .map((article) => (blok.hide_author ? { ...article, author: undefined } : article))
  );
  const [shouldShowAllArticlesButton, setShouldShowAllArticlesButton] = React.useState(false);
  const [clickedShowAll, setClickedShowAll] = React.useState(false);
  const [visibleArticles, setVisibleArticles] = React.useState(null);
  const { width } = useWindowDimensions();
  const isMobileView = useIsMobileView();

  // TODO: add useEffect to handle slugs changing in storyblok

  React.useEffect(() => {
    if (!articles.current || clickedShowAll) return;

    setShouldShowAllArticlesButton(() => {
      const dimensionType = getWindowDimensionType(width);

      switch (dimensionType) {
        case WINDOW_DIMENSION_TYPE.desktop:
        case WINDOW_DIMENSION_TYPE.laptop:
        case WINDOW_DIMENSION_TYPE.wide:
          setVisibleArticles(articles.current.slice(0, MAX_ARTICLES_TO_SHOW_BASED_ON_DIMENSION[WINDOW_DIMENSION_TYPE.desktop]));
          return articles.current && articles.current?.length > MAX_ARTICLES_TO_SHOW_BASED_ON_DIMENSION[WINDOW_DIMENSION_TYPE.desktop];
        case WINDOW_DIMENSION_TYPE.lg:
        case WINDOW_DIMENSION_TYPE.md:
          setVisibleArticles(articles.current.slice(0, MAX_ARTICLES_TO_SHOW_BASED_ON_DIMENSION[WINDOW_DIMENSION_TYPE.lg]));
          return articles.current && articles.current?.length > MAX_ARTICLES_TO_SHOW_BASED_ON_DIMENSION[WINDOW_DIMENSION_TYPE.lg];
        case WINDOW_DIMENSION_TYPE.mobile:
          setVisibleArticles(articles.current);
          return true;
        default:
          return false;
      }
    });
  }, [width, isMobileView, clickedShowAll]);

  const handleShowAllArticles = () => {
    setShouldShowAllArticlesButton(() => {
      setClickedShowAll(true);
      setVisibleArticles(articles.current);

      return false;
    });
  };

  return (
    <section
      className={cx(`${styles['n4-article-grid']} mx-auto mb-[5rem] mt-[3rem] max-w-[1344px]`, blok.className, blok.component)}
      {...storyblokEditable(blok)}
    >
      <span className={styles['grid-title']}>{blok.title}</span>
      <section className={styles['article-content']}>
        <section className={styles.cards}>
          {isMobileView && !clickedShowAll && !blok.disable_carousel ? <Carousel articles={visibleArticles} /> : <ArticleList articles={visibleArticles} />}
        </section>
        {isMobileView ? <div className={styles['carousel-indicators']} /> : null}
      </section>
      {shouldShowAllArticlesButton ? (
        <section className={styles.action} onClick={handleShowAllArticles}>
          <span className={styles.text}>Show all articles</span>
          <Button.Icon className={styles.show} icon="arrow-down-regular" />
        </section>
      ) : null}
      <ul className="hidden">
        {articles?.current?.map((article) => (
          <li key={article.id}>
            <NextLink
              prefetch={false}
              href={normalizeUrl({ url: article.slug, origin: 'article-grid#carousel' })}
              rel={article.noindex ? 'nofollow' : undefined}
            >
              {article.title}
            </NextLink>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ArticleGrid;
