/* eslint-disable no-console */
import dynamic from 'next/dynamic';

import GenericStory from 'components/layout/generic_story';
import N4Layout from 'components/layout/n4_layout';
// eslint-disable-next-line no-unused-vars
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import loadStory from 'lib/page_helpers/story';

const ProductContainer = dynamic(() => import(/* webpackChunkName: 'ProductContainer' */ 'components/bloks/n4/product/product-container'));

const Story = ({ folder, story, preview, ...props }) => {
  if (props.api.releasePage) {
    return <ProductContainer folder={folder} initialStory={story} product={props.api.release} preview={preview} {...props} />;
  }
  return <GenericStory folder={folder} story={story} preview={preview} {...props} />;
};
Story.getLayout = (children, pageProps) => <N4Layout {...pageProps}>{children}</N4Layout>;

const getStaticProps = async ({ params }) => {
  const log = logger({ category: `stories/${params.folder}${params.folder === '' ? '' : '/'}${params.slug}` });
  log.info('getStaticProps: %o', params);
  /*
  log.warn('stories force remote log');
  console.debug('stories this is a console.debug call on the server');
  console.log('stories this is a console.log call on the server');
  console.warn('stories this is a console.warn call on the server');
  console.error('stories this is a console.error call on the server');
  */
  const results = await loadStory({ ...params, origin: 'stories', version: 'published', log });
  return results;
};

const getStaticPaths = async () => {
  const log = logger({ category: 'pages/stories' });
  const slugs = ['root/index']; // when ready to prerender await fetchApi({ path: 'routes/prerenders', origin: 'pages/stories#getStaticPaths', log });
  // if we want to pre-render everything
  // const slugs = await fetchApi({ path: 'routes/prerenders', origin: 'pages/stories#getStaticPaths', log });
  const paths = slugs.reduce((arr, fullSlug) => {
    const [folder, ...rest] = fullSlug.split('/');
    // Only doing A for now
    // ['A', 'B'].forEach((variant) => {
    ['A'].forEach((variant) => {
      arr.push({ params: { folder, slug: rest.join('/'), variant: [variant] } });
    });
    return arr;
  }, []);
  // eslint-disable-next-line no-console
  log.info('paths: %o', paths);
  return {
    paths,
    fallback: 'blocking',
  };
};

export { getStaticPaths, getStaticProps };

export default Story;
