import React from 'react';

import { Prices } from 'components/bloks/n4/product/prices';
import type { Release } from 'types/release';

interface PricesTabProps {
  release: Release;
}

export const PricesTab: React.FC<PricesTabProps> = ({ release }) => {
  return (
    <div className="py-[32px]">
      <Prices release={release} isProductPage={false} />
    </div>
  );
};
