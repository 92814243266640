import * as React from 'react';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { motion, useScroll, useTransform, useMotionValueEvent } from 'framer-motion';
import { debounce } from 'lodash';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';

import { Button } from 'components/common-n4/button';
import * as Popover from 'components/common-n4/popover';
// import * as Popover from '@radix-ui/react-popover';
import { Topbar } from 'components/layout/storyblok_nav/components/topbar';
import SvgIcon from 'components/ui/svg_icon';
import { useApiData } from 'hooks';
import { useIsMediumOrMobileView } from 'hooks/use_is_mobile_view';
import { useWindowDimensions } from 'hooks/use_window_dimensions';
import logger from 'lib/logger';
import { useStoryblok } from 'lib/storyblok';
import { cx, normalizeUrl } from 'lib/utils';

import styles from './storyblok_nav.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'storyBlok-nav' });

const SeoMenu = ({ api, menuItems }) => {
  const links = {};
  menuItems.forEach((panelContainer) => {
    links[panelContainer.title] = {};
    panelContainer.panels.forEach((panel) => {
      links[panelContainer.title][panel.title] = {};
      (panel.panel_items || []).forEach((item) => {
        if (item.component === 'nav-article') {
          const article = api.articles[item.slug];
          if (article) {
            const url = normalizeUrl({ url: item.slug, origin: 'seo-links' });
            links[panelContainer.title][panel.title][url] = { url, title: article.title };
          }
        } else if (item.component === 'nav-custom-link') {
          const url = item.link.url || item.link.cached_url;
          if (url) {
            const nUrl = normalizeUrl({ url, origin: 'seo-links' });
            links[panelContainer.title][panel.title][nUrl] = { url: nUrl, title: item.title };
          }
        } else if (item.component === 'nav-product' && item.form_factor) {
          const ff = api.models[item.form_factor];
          if (ff) {
            const url = normalizeUrl({ url: ff.release.path, origin: 'seo-links' });
            links[panelContainer.title][panel.title][url] = { url, title: item.title || ff.release.full_name };
          }
        }
      });
      (panel.panel_tabs || []).forEach((tab) => {
        links[panelContainer.title][panel.title][tab.title] = {};
        (tab.items || []).forEach((item) => {
          if (item.component === 'nav-article') {
            const article = api.articles[item.slug];
            if (article) {
              const url = normalizeUrl({ url: article.slug, origin: 'seo-links' });
              links[panelContainer.title][panel.title][tab.title][url] = { url, title: article.title };
            }
          } else if (item.component === 'nav-custom-link') {
            const url = item.link.url || item.link.cached_url;
            if (url) {
              const nUrl = normalizeUrl({ url, origin: 'seo-links' });
              links[panelContainer.title][panel.title][tab.title][nUrl] = { url: nUrl, title: item.title };
            }
          } else if (item.component === 'nav-product' && item.form_factor) {
            const ff = api.models[item.form_factor];
            if (ff) {
              const url = normalizeUrl({ url: ff.release.path, origin: 'seo-links' });
              links[panelContainer.title][panel.title][tab.title][url] = { url, title: item.title || ff.release.full_name };
            }
          }
        });
      });
    });
  });
  return (
    <nav aria-label="Main Navigation">
      <ul>
        {Object.entries(links).map(([title, childs]) => (
          <li key={title}>
            {title}
            <ul>
              {Object.entries(childs).map(([cTitle, cChilds]) => (
                <li key={cTitle}>
                  {cTitle}
                  <ul>
                    {Object.entries(cChilds).map(([gTitle, gChilds]) => (
                      <li key={gTitle}>
                        {gChilds.url ? (
                          <a href={gChilds.url}>{gChilds.title}</a>
                        ) : (
                          <>
                            {gTitle}
                            <ul key={gTitle}>
                              {Object.values(gChilds).map((ggLink) => (
                                <li key={ggLink.title || ggLink.url}>
                                  <a href={ggLink.url}>{ggLink.title}</a>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const PopoverContent = ({ menuItemTitle, handleSetNavActive, currentMenuItem, setCurrentMenuItem, story, nav, handleReset, handleSearch, ...props }) => {
  const isMediumOrLessViewport = useIsMediumOrMobileView();
  const isCurrentMenuItemSearch = currentMenuItem?.title === 'Search';
  const { open } = Popover.usePopoverContext();

  const expand = story?.full_slug === 'components/nav';

  const menuItems = React.useMemo(() => nav?.blocks?.filter((menuItem) => expand || menuItem.title !== 'Search') || [], [nav?.blocks, expand]);

  React.useEffect(
    () => {
      handleSetNavActive(menuItemTitle, open);
    },
    // passing handleSetNavActive
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, menuItemTitle]
  );

  return (
    <Popover.Portal>
      <Popover.Content>
        {currentMenuItem && (
          <StoryblokComponent
            blok={currentMenuItem}
            story={story}
            isMediumOrLessViewport={isMediumOrLessViewport}
            isSearch={isCurrentMenuItemSearch}
            handleResetNavMenu={handleReset}
            {...props}
            {...storyblokEditable(currentMenuItem)}
          />
        )}
        {!currentMenuItem && (
          <section className={styles.mobile}>
            <section className={styles.content}>
              {/*
              <div className={styles['form-wrapper']}>
                <section className={styles.form}>
                  <div>
                    <SvgIcon icon="magnifying-glass-02" aria-hidden className={styles['magnifying-glass']} />
                  </div>
                  <input ref={searchRef} name="nav-search" value={searchTerm} onChange={handleSearchTerm} placeholder="Search" />
                </section>
              </div>
              */}
              <section className={styles.items}>
                {menuItems?.map((menuItem) => (
                  <button
                    key={menuItem._uid}
                    className={styles.item}
                    type="button"
                    onClick={() => {
                      setCurrentMenuItem(menuItem);
                    }}
                  >
                    {menuItem.title}
                    <SvgIcon icon="chevron-right-solid" alt="menu button" />
                  </button>
                ))}
              </section>
            </section>
            <Button.Primary onClick={handleSearch} className={styles['n4-topbar-button']}>
              Search
            </Button.Primary>
          </section>
        )}
      </Popover.Content>
    </Popover.Portal>
  );
};

const StoryblokNav = (props) => {
  const { isHomepage, showHeaderPopup, handleHeaderPopupClose } = props;
  const { story } = useStoryblok() || {};
  const api = useApiData() || {};
  const { nav } = api;
  const menuItems = React.useMemo(() => nav?.blocks?.filter((menuItem) => menuItem.title !== 'Search') || [], [nav?.blocks]);
  const search = React.useMemo(() => nav?.blocks?.find((menuItem) => menuItem.title === 'Search') || [], [nav?.blocks]);
  const [currentMenuItem, setCurrentMenuItem] = React.useState(null);
  const isCurrentMenuItemSearch = currentMenuItem?.title === 'Search';
  const navRef = React.useRef(null);
  const [showNavbar, setShowNavbar] = React.useState(true);
  // There are 3 different popover triggers, they are all in their own context and I do not want to
  // render nav for each menu items since its always rendered while the popovers are not.
  const [panelsActive, setPanelsActive] = React.useState({});
  const isNavActive = React.useMemo(() => Boolean(Object.values(panelsActive)?.find((isActive) => isActive)), [panelsActive]);
  const isMediumOrLessViewport = useIsMediumOrMobileView();
  const [isAtTop, setIsAtTop] = React.useState(true);
  const [backgroundColor, setBackgroundColor] = React.useState('transparent');
  const { height } = useWindowDimensions(true);
  const { scrollY } = useScroll();
  const backgroundColors = useTransform(scrollY, [0, height], ['rgba(0,0,0,0.0)', 'rgb(254, 249, 240)']);
  const isWhite = React.useMemo(() => isHomepage && !isNavActive && isAtTop, [isHomepage, isNavActive, isAtTop]);
  const [scrollPosition, setScrollposition] = React.useState(0);
  // I don't see that product pages need any special logic, but if we figure it out
  //
  const isProductPage = story?.content?.blocks?.some((b) => b.component === 'n4-product-config') ?? false;
  // eslint-disable-next-line no-unused-vars
  const [animateNav, setAnimateNav] = React.useState(true);

  // set all panels active to false by default
  React.useEffect(() => {
    const panels = {};
    nav?.blocks?.forEach((item) => {
      panels[item.title] = false;
    });
    setPanelsActive(panels);
  }, [nav?.blocks]);

  const handleSetNavActive = (menuItem, isActive = false) => {
    setPanelsActive({
      ...panelsActive,
      [menuItem]: isActive,
    });
  };

  const handleSearch = () => {
    setCurrentMenuItem(search);
  };

  const handleReset = () => {
    setCurrentMenuItem(undefined);
  };

  const updateIsAtTop = React.useCallback(
    (currentScrollY) => {
      if (!height) {
        return;
      }
      const navbarHeight = navRef.current.getBoundingClientRect().height;
      if (isHomepage && currentScrollY < height - navbarHeight) {
        setIsAtTop(true);
      } else {
        setIsAtTop(false);
      }
    },
    [height, isHomepage]
  );

  const onPopoverClose = React.useCallback(() => setCurrentMenuItem(undefined), []);

  React.useEffect(() => {
    // This isn't changing anything, but if there is some case where we want to set it to
    // false, do so here. But not all the time or the nav disappears forever.
    if (isProductPage) {
      setAnimateNav(true);
    }
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const navbarHeight = 20; // Start hiding navbar after scrolling first 20 pixels
      const hasScrolledDown = window.scrollY > lastScrollY;
      const shouldStartHidingNavbar = window.scrollY > navbarHeight;

      if (!isNavActive) {
        // Only update the navbar visibility if the navbar is not active
        if (shouldStartHidingNavbar) {
          // Toggle its visibility based on scroll direction after scrolling past the navbar height
          setShowNavbar(!hasScrolledDown);
        } else {
          // Always show the navbar if the user hasn't scrolled down past the navbar height
          setShowNavbar(true);
        }
      } // If the navbar is active, do nothing, keeping the current visibility state

      lastScrollY = window.scrollY;
    };

    // Debounce the scroll handler to improve performance
    const debouncedHandleScroll = debounce(handleScroll, 10);

    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [panelsActive, isMediumOrLessViewport, isNavActive, isProductPage]); // Include isMediumOrLessViewport in the dependencies array

  // each menu items needs its own popover context otherwise there are issues and this is also
  // how Radix team (Popover is using Radix primitives) recommends to setup multiple triggers */

  useMotionValueEvent(scrollY, 'change', (latest) => {
    setScrollposition(latest);
    setBackgroundColor(backgroundColors.get());
    updateIsAtTop(latest);
  });

  React.useEffect(() => {
    updateIsAtTop(window.scrollY);
  }, [isHomepage, updateIsAtTop]);

  const getTransformValue = () => {
    if (showNavbar) {
      return isMediumOrLessViewport
        ? 'translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
        : 'translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)';
    }
    return isMediumOrLessViewport
      ? 'translate3d(0px, -11rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
      : 'translate3d(0px, -8rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)';
  };

  return (
    <motion.section
      className={cx(
        styles['storyblok-nav'],
        isNavActive && styles['storyblok-nav--active'],
        isHomepage && styles['storyblok-nav--transparent'],
        isProductPage && '!absolute'
      )}
      ref={navRef}
      transition={{
        transform: showNavbar ? { duration: 0.8, type: 'tween', ease: [0.22, 1, 0.36, 1] } : { duration: 0.3, type: 'tween', ease: 'easeIn' },
      }}
      initial={{
        transform: 'translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
      }}
      animate={
        animateNav && {
          transform: getTransformValue(),
        }
      }
      style={{
        transformStyle: 'preserve-3d',
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isNavActive ? '#ffffff' : isHomepage ? (scrollPosition === 0 ? 'transparent' : backgroundColor) : '#fef9f0',
      }}
    >
      {showHeaderPopup && (
        <div className="relative flex min-w-full items-center justify-center bg-navy px-[23px] py-[12px]">
          <span className="grid whitespace-normal text-[14px] font-[250] leading-[130%] text-gray-light xs:inline-block">
            We independently review each item we recommend and appreciate your trust and support. When you buy through our links, we may earn a commission.
            <NextLink prefetch={false} href="/expert-vetting-our-hearing-aid-review-process" className="text-center">
              <span className="link pointer text-[#98C1FF] no-underline"> Learn More</span>
            </NextLink>
          </span>
          <SvgIcon
            onClick={handleHeaderPopupClose}
            icon="close-regular"
            className="absolute right-[10px] h-[9px] w-[9px]"
            style={{ filter: 'invert(100%) sepia(92%) saturate(6%) hue-rotate(200deg) brightness(107%) contrast(100%)' }}
            alt="menu button"
          />
        </div>
      )}
      <Topbar isHomepage={isHomepage}>
        <Topbar.Logo isWhite={isWhite} />
        <section className={styles.items}>
          <div style={{ display: 'none' }}>
            <SeoMenu api={api} menuItems={menuItems} />
          </div>
          {menuItems?.map((menuItem) => (
            <Popover.Root key={menuItem._uid} onClose={onPopoverClose}>
              <Popover.Anchor asChild>
                <button type="button" className={styles['popover-trigger']} />
              </Popover.Anchor>
              <Popover.Trigger asChild>
                <section className={cx(styles.item, isWhite && styles['item--white'])}>
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentMenuItem(menuItem);
                    }}
                    className={isWhite ? styles.white : undefined}
                  >
                    {menuItem.title}
                    <SvgIcon icon="chevron-down-solid" alt="menu dropdown button" />
                  </button>
                </section>
              </Popover.Trigger>
              <PopoverContent
                menuItemTitle={menuItem.title}
                handleSetNavActive={handleSetNavActive}
                currentMenuItem={currentMenuItem}
                story={story}
                nav={nav}
                handleReset={handleReset}
                handleSearch={handleSearch}
                setCurrentMenuItem={setCurrentMenuItem}
                {...props}
              />
            </Popover.Root>
          ))}
        </section>

        <Popover.Root onClose={onPopoverClose}>
          <Popover.Anchor asChild>
            <button type="button" className={styles['popover-trigger']} />
          </Popover.Anchor>
          <TopbarRight isCurrentMenuItemSearch={isCurrentMenuItemSearch} search={search} setCurrentMenuItem={setCurrentMenuItem} isWhite={isWhite} />
          <PopoverContent
            menuItemTitle={search.title}
            handleSetNavActive={handleSetNavActive}
            currentMenuItem={currentMenuItem}
            story={story}
            nav={nav}
            handleReset={handleReset}
            handleSearch={handleSearch}
            setCurrentMenuItem={setCurrentMenuItem}
            {...props}
          />
        </Popover.Root>
      </Topbar>
    </motion.section>
  );
};

const TopbarRight = ({ isCurrentMenuItemSearch, search, setCurrentMenuItem, ...props }) => {
  const { isWhite } = props;
  const { open, setOpen } = Popover.usePopoverContext();
  const pathname = usePathname();
  const [isComparePage, setIsComparePage] = React.useState(false);

  React.useEffect(() => {
    setIsComparePage(!props.is404 && pathname.includes('/compare'));
  }, [pathname, props.is404]);

  return (
    <section className={styles.right}>
      {!isComparePage ? (
        <Button.LinkPrimary
          href={isComparePage ? 'https://guide.hearingtracker.com/survey' : '/hearing-aids/compare'}
          shouldOpenNewTab={false}
          className={styles['n4-topbar-button']}
        >
          <span className={styles.text} key="btn-txt-full">
            {isComparePage ? 'Download Guide ' : 'Compare Hearing Aids'}
          </span>
          <span className={styles['text--sm']} key="btn-txt-mobile">
            {isComparePage ? 'Guide' : 'Compare'}
          </span>
        </Button.LinkPrimary>
      ) : (
        <div className="md:w-[165px]" />
      )}
      <section className={styles.search}>
        {open && isCurrentMenuItemSearch ? (
          <Topbar.Close
            onClick={() => {
              setOpen(() => {
                setCurrentMenuItem(undefined);
                return false;
              });
            }}
          />
        ) : (
          <Topbar.Search
            isWhite={isWhite}
            onClick={() => {
              setOpen(() => {
                setCurrentMenuItem(search);
                return true;
              });
            }}
          />
        )}
      </section>
      <section className={styles['search--mobile']}>
        {open ? (
          <Topbar.Close
            onClick={() => {
              setOpen(() => {
                setCurrentMenuItem(undefined);
                return false;
              });
            }}
          />
        ) : (
          <Topbar.Toggler
            isWhite={isWhite}
            onClick={() => {
              setOpen(true);
            }}
          />
        )}
      </section>
    </section>
  );
};

export default StoryblokNav;
