import React, { PropsWithChildren } from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { findBloks } from 'lib/storyblok';
import { tw } from 'lib/utils';
import { Blok } from 'types/blok';
import { TitleAndMetaStoryblok } from 'types/storyblok-component-types';

import styles from '../article.module.scss';

interface ArticleTitleProps extends PropsWithChildren {
  blok: TitleAndMetaStoryblok;
  story: Story;
  published: string;
  updated: string;
  className?: ClassNameValue;
}

interface Story {
  name: string;
  full_slug: string;
  published_at: string;
  created_at: string;
  content: {
    blocks: Blok[];
  };
}

const words = (blok: Story) => {
  const markdowns = findBloks(blok, 'n4-markdown');
  return markdowns.reduce((sum: number, item: TitleAndMetaStoryblok) => sum + item.content.split(' ').length, 0);
};

const getReadingTime = (wordCount: number) => {
  const wordsPerMinute = 400;
  const minutes = wordCount / wordsPerMinute;

  return Math.ceil(minutes);
};

export const ArticleTitle: React.FC<ArticleTitleProps> = ({ blok, story, published, updated, className = '' }) => {
  let tag = 'Editorial';

  const url = story.full_slug || ''; // Ensure you get the full URL or slug
  if (url.includes('opinion/')) {
    tag = 'Opinion';
  } else if (url.includes('press-releases/')) {
    tag = 'Press Release';
  } else if (url.includes('resources/')) {
    tag = 'Resources';
  } else if (url.includes('news/')) {
    tag = 'News';
  } else if (url.includes('pro-news/')) {
    tag = 'Pro News';
  } else if (url.includes('hearing-loss/') || url === 'hearing-loss') {
    tag = 'Hearing Loss';
  } else if (url.includes('tinnitus/') || url === 'tinnitus') {
    tag = 'Tinnitus';
  } else if (url.includes('earwax/') || url === 'earwax') {
    tag = 'Earwax';
  } else if (url.includes('earplugs/') || url === 'earplugs') {
    tag = 'Earplugs';
  }

  const productPage = story.content.blocks.some((b) => b.component === 'n4-product-config');

  if (blok?.sponsor) {
    tag = 'Sponsored';
  } else if (productPage) {
    tag = 'Product Review';
  }

  return (
    <section className={styles.details}>
      {!productPage && (
        <section className={styles.meta}>
          <div>{tag}</div>
          {updated || published ? (
            <span>{updated ? `Updated on ${updated}` : `Published on ${published}`}</span>
          ) : (
            <span>{getReadingTime(words(story) || 1)} min read</span>
          )}
        </section>
      )}
      {/* article title is always an h1 for SEO */}
      <h1 className={tw(className)}>{blok.title || story.name}</h1>
      {blok?.subtitle ? (
        <span
          className="text-[1.4rem] font-[400] leading-[1.5] tracking-[-0.0125rem] 
        text-[#43516b] md:text-[1.5rem] md:font-[300] md:leading-[1.7] max-xs:-mt-2"
        >
          {blok?.subtitle}
        </span>
      ) : null}
    </section>
  );
};
