import React, { useCallback, useContext, useState } from 'react';

import { Transition } from '@headlessui/react';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants';
import { Skeleton } from 'components/common/skeleton/skeleton-ts';
import { Button } from 'components/common-n4/button';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import Link from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import { ProductInfoPopup } from 'components/common-n4/product-info/product-info-popup';
import SimplePrice from 'components/widgets/simple-price';
import IconChevronUp from 'images/icon-chevron-up.svg';
import logger from 'lib/logger';
import { formatCurrency, normalizeUrl, tw } from 'lib/utils';

import { Final } from './final';
import { MultipleChoice } from './multiple-choice';
import { Range } from './range';
import { SingleChoice } from './single-choice';
import { type Product, UserAction, Widget } from './types';
import { findMatches } from './utils';

import { WizardContext } from '.';

const log = logger({ category: 'wizard/step' });

const origin = 'wizard';

interface ProductProps {
  product?: Product;
  itemIndex: number;
}

const Match: React.FC<ProductProps> = ({ product, itemIndex }) => {
  const [productInfoState, setProductInfoState] = React.useState({ isOpen: false, initialTab: 'details' });

  const price = product?.price;

  return (
    <div className="flex gap-5 p-2">
      <div className="relative h-[96px] w-[144px] cursor-pointer">
        {product ? (
          <Link href={normalizeUrl({ url: product?.path, origin: 'wizard/step/match' })} target="_blank">
            <ImageWrapper
              image={product.image}
              imgProps={{ className: '!object-contain rounded-[10px] !w-[140px] !h-[93px] desktop:!w-[144px] desktop:!h-[96px]' }}
              origin={origin}
            />
          </Link>
        ) : (
          <Skeleton className="!h-[93px] !w-[140px] rounded-[10px] desktop:!h-[96px] desktop:!w-[144px]" />
        )}
        <div
          className={tw(
            'absolute -left-[9px] -top-[9px] flex h-[22px] w-[22px] items-center',
            'justify-center rounded-full bg-navy-5 text-[12px] font-semibold shadow-[0_0_0_4px_white]'
          )}
        >
          {itemIndex + 1}
        </div>
        {product?.score && (
          <CircleScoreFluid
            size="xs"
            amount={product.score}
            progress={(product.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
            className="absolute right-1 top-1 shadow-[1px_2px_2px_0_rgba(0,0,0,0.15)]"
            origin="article-header"
          />
        )}
      </div>
      <div className="flex flex-1 flex-col justify-between gap-4">
        <div className="">
          <button className="text-left text-base leading-[120%]" onClick={() => setProductInfoState({ isOpen: true, initialTab: 'details' })}>
            {product ? (
              `${product.fullName}`
            ) : (
              <div className="w-36">
                <Skeleton height="22px" />
              </div>
            )}
          </button>
          {/* <div className="text-lapis">Price: {`${product.price?.min}-${product.price?.max}`}</div> */}
          <div className="mt-1 text-[18px] tracking-tight text-lapis lg:text-[14px] lg:leading-[140%]">
            {price ? (
              <>
                {price.url ? (
                  <SimplePrice
                    uuid={String(product.hearingAidId)}
                    url={price.url}
                    position="wizard-sidebar-text"
                    origin="wizard/step/match/text"
                    forceCta="starting-at"
                    forceText={`Starting at ${formatCurrency(price.min)}`}
                    slug={product.releaseSlug}
                    seller={price.seller}
                    releaseName={product.fullName}
                    partnerType={price.partnerType}
                    display="link"
                  />
                ) : (
                  <Link
                    className={tw('!justify-normal !gap-[0.2rem] !p-0')}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setProductInfoState({ isOpen: true, initialTab: 'prices' });
                    }}
                  >
                    Starting at {formatCurrency(price.min)}
                  </Link>
                )}
              </>
            ) : (
              <Skeleton height="16px" />
            )}
          </div>
        </div>
        {price ? (
          <>
            {price.url ? (
              <SimplePrice
                uuid={String(product.hearingAidId)}
                url={price.url}
                position="wizard-sidebar-button"
                origin="wizard/step/match/button"
                slug={product.releaseSlug}
                releaseName={product.fullName}
                seller={price.seller}
                partnerType={price.partnerType}
                display="button"
                className={tw(
                  '!px-auto flex min-h-[48px] cursor-pointer items-center !justify-center gap-[0.4rem] px-[1.4rem] py-[0.8rem]',
                  'button_button--primary__tCs7L justify-center text-center text-[16px] leading-[1.5rem] tracking-[-0.01019rem] laptop:min-w-[205px]',
                  '!min-h-[32px] !w-[152px] !min-w-0 !py-[6px] !text-sm'
                )}
              />
            ) : (
              <Button.LinkPrimary
                loader={false}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  setProductInfoState({ isOpen: true, initialTab: 'prices' });
                }}
                href="#"
                className={tw(
                  'mt-0 min-h-0 w-full px-[12px] py-[6px] text-[14px] leading-[140%] tracking-tight',
                  '@2xl/prices:text-[16px] laptop:w-auto laptop:min-w-0 laptop:px-[18px] laptop:py-[10px]'
                )}
              >
                View Prices
              </Button.LinkPrimary>
            )}
          </>
        ) : (
          <div className="w-36">
            <Skeleton height="34px" className="!rounded-[1.7rem]" />
          </div>
        )}
      </div>
      {product && (
        <ProductInfoPopup
          releaseSlug={product.releaseSlug}
          modelId={product.modelId}
          hearingAidId={product.hearingAidId}
          isOpen={productInfoState.isOpen}
          setIsOpen={(isOpen: boolean) => {
            setProductInfoState((s) => ({ ...s, isOpen }));
          }}
          initialTab={productInfoState.initialTab}
        />
      )}
    </div>
  );
};

export const Step: React.FC = () => {
  const { state, dispatch } = useContext(WizardContext);
  const { userPriceRange, answers, products } = state;
  const [isShowing, setIsShowing] = useState(true);
  const [matches, setMatches] = useState<Product[]>([]);

  React.useEffect(() => {
    setMatches(findMatches({ answers, products, log, priceRange: userPriceRange, count: 3 }));
  }, [answers, products, userPriceRange]);

  const onAnwserSelect = useCallback(() => {
    setIsShowing(false);
    setTimeout(() => setIsShowing(true), 50);
  }, []);

  const question = state.questions[state.step];

  const compareUrl = [...matches] // don't want to sort the actual array
    ?.sort((a, b) => a.slug.localeCompare(b.slug))
    ?.map((p) => p.slug)
    .join('-vs-');

  return (
    <div
      className={tw(
        'flex flex-col justify-between gap-[40px] rounded-[20px] bg-white px-[20px] py-[32px]',
        'pb-[40px] text-navy shadow-xl lg:flex-row lg:gap-0 lg:px-8 lg:pb-10 lg:pt-8'
      )}
    >
      <div className="lg:max-w-[320px]">
        <div className="flex flex-col gap-[24px] lg:gap-6">
          <div className="pr-4 text-[24px] leading-[140%] tracking-tight lg:text-2xl">{question.question}</div>
          <div className="flex flex-col gap-2">
            {question.type === Widget.SingleChoice && <SingleChoice onAnwserSelect={onAnwserSelect} />}
            {question.type === Widget.MultipleChoice && <MultipleChoice onAnwserSelect={onAnwserSelect} />}
            {question.type === Widget.Final && <Final />}
          </div>
          <div className={tw('flex items-center justify-between', state.step === 0 && 'justify-end')}>
            {state.step > 0 && (
              <Button.LinkButton key="back" className="!w-auto !px-0 !py-[10px]" onClick={() => dispatch({ type: UserAction.Back })}>
                <IconChevronUp key="icon" className="-rotate-90" />
                Back
              </Button.LinkButton>
            )}
            {state.step < state.questions.length - 1 && (
              <Button.Primary key="next" onClick={() => dispatch({ type: UserAction.Next })} className="!w-[108px] !py-[10px] !leading-[140%]">
                Next
              </Button.Primary>
            )}
          </div>
        </div>
        <hr className="my-[32px] border-t border-navy-10 lg:my-8" />
        {products.length > 0 && <Range />}
      </div>
      {!state.fetching && matches.length === 0 && (
        <div className="max-w-[380px]">
          <h5>No Results</h5>No matching products were found. Please undo your current selection or go back and modify previous selections to find products
          available for your current selection.
        </div>
      )}
      {state.fetching && (
        <div className="flex flex-col justify-between gap-[40px] overflow-hidden lg:basis-[360px] lg:gap-8">
          <Transition
            unmount={false}
            show={isShowing}
            enter="transform transition duration-[400ms]"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transform duration-50 transition ease-in-out"
            leaveFrom="opacity-100 rotate-0 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex flex-col gap-4">
              <Match itemIndex={0} />
              <Match itemIndex={1} />
              <Match itemIndex={2} />
            </div>
          </Transition>
        </div>
      )}
      {!state.fetching && matches.length > 0 && (
        <div className="flex flex-col justify-between gap-[40px] overflow-hidden lg:basis-[360px] lg:gap-8">
          <Transition
            unmount={false}
            show={isShowing}
            enter="transform transition duration-[400ms]"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transform duration-50 transition ease-in-out"
            leaveFrom="opacity-100 rotate-0 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="flex flex-col gap-4">
              {matches.map((match, idx) => (
                <Match key={match.hearingAidId} itemIndex={idx} product={match} />
              ))}
            </div>
          </Transition>
          {matches.length > 1 && (
            <div className="flex items-center justify-between rounded-2xl bg-navy-5 py-[12px] pl-[16px] pr-[8px]">
              <div className="">
                <div className="text-[12px] leading-[130%] tracking-tight text-lapis">Product Compare</div>
                <div className="text-[16px] leading-[140%] tracking-tight">Compare these options</div>
              </div>
              <Button.LinkPrimary target="_blank" href={`/hearing-aids/compare/${compareUrl}`} className="!w-[108px] !py-[6px] !text-[14px]">
                Compare
              </Button.LinkPrimary>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
