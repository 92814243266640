import React from 'react';

import { usePathname } from 'next/navigation';

import { Button } from 'components/common-n4/button';
import { Link } from 'components/common-n4/link';
import { ProductInfoPopup } from 'components/common-n4/product-info/product-info-popup';
import { useOncePerPage } from 'hooks';
import { AbLinkPosition, getAb, recordAbEvent, type AbButtonPosition } from 'lib/ab-tests';
import Logger from 'lib/logger';
import { getMatchingOffers, scrollToAnchorWithOffset, utmize } from 'lib/ts-utils';
import { normalizeUrl, tw } from 'lib/utils';
import type { Release, Model, HearingAid, ReleasePrices } from 'types/release';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = Logger({ category: 'price-button' });
/*
                    R
          TL1                TL2
  HA1(FF1) HA2(FF2)  HA3(FF1)  HA4 (FF3)

  For the purpose of showing the popup:
    if the card is showing a hearing aid:
      HA1 is a go if we have HA1 or TL1
      HA2 is a go if we have HA2 or TL1
      HA3 is a go if we have HA3 or TL2
      HA4 is a go if we have HA4 or TL2

    if the card is showing a form factor (model):
      FF3 is a go if we have TL1 or TL2
      FF2 is only if we have TL1
      FF3 is only if we have TL2

  For the purpose of showing the starting price:
    All of the above applies, the only difference
    is the ha price is used if there is one.

*/

interface PriceButtonProps {
  release: Release;
  model?: Model;
  hearingAid?: HearingAid;
  className?: string;
  noGeo?: boolean;
  origin: string;
  position: AbButtonPosition | AbLinkPosition;
  prices?: ReleasePrices;
  display?: 'link' | 'button';
  linkPrefix?: string;
  forceCta?: string; // override segment cta with given cta, but still record
  forceText?: string; // override segment text with given text but still record
  useFallback?: boolean; // use the default text that is shown if it can't find a segment
  displayRange?: boolean;
  onViewPrices?: () => void;
}

const elementType = 'button';
const event = 'AffiliateAction';

const generateTestId = ({ release, model, hearingAid }: { release?: Release; model?: Model; hearingAid?: HearingAid }): string => {
  if (hearingAid) {
    return `ha-${hearingAid.slug || hearingAid.id}`;
  }
  if (model) {
    return `mo-${model.slug || model.id}`;
  }
  if (release) {
    return `r-${release.slug}`;
  }
  return '';
};

const PriceButton: React.FC<PriceButtonProps> = ({
  release,
  model,
  hearingAid,
  className,
  origin,
  position,
  prices,
  linkPrefix = '',
  display = 'button',
  forceText,
  forceCta,
  useFallback,
  displayRange = false,
  onViewPrices,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  noGeo = true,
}) => {
  const [isProductInfoPopupOpen, setIsProductInfoPopupOpen] = React.useState(false);
  const [segment, setSegment] = React.useState(-2);
  const useOnce = useOncePerPage();
  const pathname = usePathname();
  const [buttonState, setButtonState] = React.useState({
    text: 'Loading...',
    link: '',
    disabled: true,
    shouldOpenTab: false,
    cta: '',
    seller: '',
    viewPrices: false,
  });
  const [clicked, setClicked] = React.useState(0);
  const random = React.useRef(String(Math.floor(Math.random() * 10 ** 8)));

  const testName = position;
  const testId = generateTestId({ release, model, hearingAid });

  const offers = React.useMemo(() => getMatchingOffers({ prices, model, hearingAid }), [prices, model, hearingAid]);

  if (useOnce({ item: `${testName}-${testId}-impression`, skip: !release || segment < 0 || offers.length !== 1 })) {
    const offer = offers[0];
    const seller = prices?.sellers[offer.sellerId];
    recordAbEvent({
      event,
      testName,
      position,
      segment,
      origin,
      cta: buttonState.cta,
      text: buttonState.text,
      properties: {
        action: 'impression',
        elementType,
        partnerType: seller!.type,
        release: release.slug,
        seller: seller!.name,
        link: utmize(offer.url, testName, random.current),
        random: random.current,
        testId,
      },
    });
  }

  if (useOnce({ item: `${testName}-${testId}-click`, skip: !release || segment < 0 || clicked !== 1 || offers.length !== 1 })) {
    const offer = offers[0];
    const seller = prices?.sellers[offer.sellerId];
    recordAbEvent({
      event,
      testName,
      position,
      segment,
      origin,
      cta: buttonState.cta,
      text: buttonState.text,
      properties: {
        action: 'click',
        elementType,
        partnerType: seller!.type,
        release: release.slug,
        seller: seller!.name,
        link: utmize(offer.url, testName, random.current),
        random: random.current,
        testId,
      },
    });
  }

  React.useEffect(() => {
    const onReleasePage = pathname === release.path;
    if (offers.length > 1) {
      if (useFallback) {
        if (display === 'link') {
          setSegment(97);
          let text = '';
          const [offer] = offers;
          const lastOffer = offers.slice(-1)[0];
          if (displayRange && offer.price !== lastOffer.price) {
            text = `${offer.price} - ${lastOffer.price} / ${/monthly/.test(offer.priceType) ? ' mth' : ' pair'}`;
          } else if (offer.price === lastOffer.price) {
            text = `${offer.price}${/monthly/.test(offer.priceType) ? ' / mth' : ' / pair'}`;
          } else {
            text = `From ${offer.price}${/monthly/.test(offer.priceType) ? ' / mth' : ' / pair'}`;
          }
          setButtonState({ text, link: '', disabled: false, shouldOpenTab: false, cta: 'fallback', seller: '', viewPrices: onReleasePage || !!onViewPrices });
        }
      } else {
        setButtonState({
          text: 'View Prices',
          link: '',
          disabled: false,
          shouldOpenTab: false,
          cta: 'view-prices',
          seller: '',
          viewPrices: onReleasePage || !!onViewPrices,
        });
      }
      return; // just going to show View Prices unless useFallback
    }
    if (offers.length === 0) {
      if (onReleasePage) {
        setButtonState((prev) => ({ ...prev, text: 'No Current Offers', link: '', disabled: true, shouldOpenTab: false, cta: '', seller: '' }));
      } else {
        if (release.path) {
          setButtonState((prev) => ({
            ...prev,
            text: 'Product Info',
            link: normalizeUrl({ url: release.path, origin: 'price-button' }),
            disabled: false,
            shouldOpenTab: false,
            cta: 'Product Info',
            seller: '',
          }));
        } else {
          setButtonState((prev) => ({
            ...prev,
            text: 'Archived Product',
            link: '',
            disabled: true,
            shouldOpenTab: false,
            cta: '',
            seller: '',
          }));
        }
      }
      return;
    }
    const offer = offers[0];
    const seller = release!.prices!.sellers[offer.sellerId];
    const ab = getAb(testName, { release: release.name, price: offer.price, local: offer.url?.includes('ziphearing') ? 'with local service' : '' });
    if (forceCta && forceText) {
      ab.text = forceText;
      ab.cta = forceCta;
      ab.segment = 98;
    } else if (offer.clickText) {
      ab.text = offer.clickText;
      ab.cta = 'click_test';
      ab.segment = 99;
    }
    const { cta } = ab;
    let { text } = ab;
    setSegment(forceText ? 99 : ab.segment);
    if (ab.segment < 0 || ab.segment > 100 || useFallback) {
      if (display === 'button') {
        text = 'View Details';
      } else {
        text = `${displayRange ? '' : 'From '}${offer.price} a pair${/ziphearing/.test(offer.url) ? ' with local service' : ''}`;
      }
    }
    setButtonState((prev) => ({
      ...prev,
      text,
      link: utmize(offer.url, testName, random.current),
      disabled: false,
      shouldOpenTab: true,
      cta,
      seller: seller.name,
    }));
  }, [offers, pathname, release.path, release.name, testName, random, release, forceText, forceCta, useFallback, displayRange, display, onViewPrices]);

  if (display === 'link') {
    if (offers.length > 1) {
      return (
        <>
          <ProductInfoPopup
            releaseSlug={release.slug}
            modelId={model?.id}
            useModel={!!model}
            hearingAidId={hearingAid?.id}
            isOpen={isProductInfoPopupOpen}
            setIsOpen={setIsProductInfoPopupOpen}
            initialTab="prices"
          />
          <a
            href="#"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              if (buttonState.viewPrices) {
                if (onViewPrices) {
                  onViewPrices();
                } else {
                  scrollToAnchorWithOffset('prices', -70);
                }
              } else {
                setIsProductInfoPopupOpen(true);
              }
              return false;
            }}
            className={tw('!justify-normal !gap-[0.2rem] !p-0', className)}
          >
            {buttonState.text}
          </a>
        </>
      );
    } else {
      if (buttonState.link) {
        return (
          <Link
            variant="primary"
            className={tw('!justify-normal !gap-[0.2rem] !p-0', className)}
            href={buttonState.link}
            onClick={() => {
              setClicked((count) => count + 1);
              return true;
            }}
            shouldOpenNewTab={buttonState.shouldOpenTab}
            data-track={!!buttonState.cta}
            data-event-name="Link"
            data-event-position={position}
            data-event-product={release.slug}
            data-event-cta={buttonState.cta}
            data-event-seller={buttonState.seller}
            data-event-url={buttonState.link}
          >
            {linkPrefix ? (
              <>
                {linkPrefix}
                {offers[0].price}
              </>
            ) : (
              `${buttonState.text}`
            )}
          </Link>
        );
      }
    }

    return <span className={tw('!justify-normal !gap-[0.2rem] !p-0', className)}>{buttonState.text}</span>;
  }

  if (offers.length > 1) {
    return (
      <>
        {!buttonState.viewPrices && (
          <ProductInfoPopup
            releaseSlug={release.slug}
            modelId={model?.id}
            useModel={!!model}
            hearingAidId={hearingAid?.id}
            isOpen={isProductInfoPopupOpen}
            setIsOpen={setIsProductInfoPopupOpen}
            initialTab="prices"
          />
        )}
        <Button.LinkPrimary
          loader={false}
          href="#"
          className={className || '!w-full'}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            if (buttonState.viewPrices) {
              if (onViewPrices) {
                onViewPrices();
              } else {
                scrollToAnchorWithOffset('prices', -70);
              }
            } else {
              setIsProductInfoPopupOpen(true);
            }
          }}
        >
          View Prices
        </Button.LinkPrimary>
      </>
    );
  }

  if (buttonState.link) {
    return (
      <Button.LinkPrimary
        className={className || '!w-full'}
        onClick={() => {
          setClicked((count) => count + 1);
          return true;
        }}
        href={buttonState.link}
        shouldOpenNewTab={buttonState.shouldOpenTab}
        loader={!buttonState.shouldOpenTab}
        data-track={!!buttonState.cta}
        data-event-name="Link"
        data-event-position={position}
        data-event-product={release.slug}
        data-event-cta={buttonState.cta}
        data-event-seller={buttonState.seller}
        data-event-url={buttonState.link}
      >
        {buttonState.text}
      </Button.LinkPrimary>
    );
  }

  return (
    <Button.Primary
      loader="false"
      className={className || '!w-full'}
      isFull
      isDisabled={buttonState.disabled}
      data-track={!!buttonState.cta}
      data-event-name="Link"
      data-event-position={position}
      data-event-product={release.slug}
      data-event-cta={buttonState.cta}
      data-event-seller={buttonState.seller}
      data-event-url={buttonState.link}
      onClick={() => {
        setClicked((count) => count + 1);
        return true;
      }}
    >
      {buttonState.text}
    </Button.Primary>
  );
};

export default PriceButton;
