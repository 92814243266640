import React, { HTMLProps } from 'react';

import NextLink, { LinkProps } from 'next/link';

const HTLink: React.FC<LinkProps & HTMLProps<HTMLAnchorElement>> = ({ href, children, ...props }) => {
  if (href && href !== '#') {
    // prefetch = false says to render the page on hover, but not on scroll into view
    return (
      <NextLink href={href} {...props} prefetch={false}>
        {children}
      </NextLink>
    );
  }
  const { onClick, ...attrs } = props;
  return (
    <a
      href={href || '#'}
      {...attrs}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        } else {
          e.preventDefault();
        }
      }}
    >
      {children}
    </a>
  );
};

export default HTLink;
