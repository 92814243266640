import React from 'react';

import Head from 'next/head';

import logger from 'lib/logger';
import { normalizeUrl } from 'lib/utils';

const defaultMeta = {
  meta_title: 'Untitled Page - HearingTracker.com',
  meta_description: 'Untitled Page',
};

const log = logger({ category: 'meta' });
const noindex = process.env.NOINDEX === 'true';

// Note: the TitleAndMeta component from SB will handle the per-page settings
const Meta = ({ story, ...props }) => {
  const { meta = { noindex: true, title: 'Page With No Meta' } } = props;
  if (!meta.noindex && !meta.canonical) {
    log.warn('No canonical for indexed page: %s', story?.slug || meta.title);
  }
  log.debug('meta: %o', meta);
  // key is used so if we set these downstream, like in an sb component (title_and_meta), it will only use the last one
  return (
    <Head>
      <title key="title">{meta.meta_title || defaultMeta.meta_title}</title>
      {(noindex || meta.noindex) && <meta name="robots" content="noindex" />}
      <meta charSet="UTF-8" />
      <meta httpEquiv="Content-Language" content="en" />
      <meta key="meta_description" name="description" content={meta.meta_description || defaultMeta.meta_description} />
      <meta name="mobile-web-app-capable" content="yes" />
      {/* Doing my own phone detection to avoid stupid hydration errors on ios */}
      <meta name="format-detection" content="telephone=no" />
      <meta name="msapplication-TileColor" content="#2d89ef" />
      <meta name="msapplication-TileImage" content="/favicon/mstile-144x144.png" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="msvalidate.01" content="3B994DA433B33DB18AF29DED0FA6459C" />
      <meta name="theme-color" content="#2C88C5" />
      <meta name="theme-color" content="#ffffff" />
      <meta key="twitter_card" name="twitter:card" content="summary_large_image" />
      <meta key="twitter_creator" name="twitter:creator" content="@hearingtracker" />
      <meta key="twitter_site" name="twitter:site" content="@hearingtracker" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="wot-verification" content="7d27f30c4a7a3ccc7bac" />
      <meta property="fb:admins" content="4710637" />
      <meta property="fb:app_id" content="1402805259934021" />
      <meta property="fb:pages" content="168308370044182" />
      <meta key="og_title" property="og:title" content={meta.meta_title || defaultMeta.meta_title} />
      <meta key="og_site_name" property="og:site_name" content="Hearing Tracker" />
      <meta key="og_type" property="og:type" content="website" />
      {meta.open_graph_images &&
        meta.open_graph_images.map((ogi) => <meta key={ogi} property="og:image" content={normalizeUrl({ url: ogi, origin: 'meta' })} />)}
      {meta.canonical && <meta key="og_url" property="og:url" content={meta.canonical} />}
      {meta.canonical && <link rel="canonical" href={meta.canonical} />}
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />
      <link rel="search" type="application/opensearchdescription+xml" title="HearingTracker" href="/opensearch.xml" />
      {props.rel_prev && <link rel="prev" href={props.rel_prev} />}
      {props.rel_next && <link rel="next" href={props.rel_next} />}
      {/* eslint-disable-next-line react/no-danger */}
      {props.schema && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(props.schema) }} />}
      {props.requestId && <meta name="requestId" content={props.requestId} />}
    </Head>
  );
};

export default Meta;
