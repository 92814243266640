import { type MultiFilter, type Question, Widget } from './types';

export const globalFilters: MultiFilter[] = [
  {
    answers: {
      type: 'in-the-ear',
      channel: 'telehealth',
    },
    filters: {
      styleName: ['Instant-Fit Canal', 'Instant-Fit CIC'],
    },
  },
];

export const questions: Question[] = [
  {
    id: 'type',
    question: 'What type of hearing aid do you prefer?',
    type: Widget.SingleChoice,
    options: [
      { text: 'In the ear', value: 'in-the-ear', filters: [{ style: ['In the Ear', 'In Canal'] }] },
      { text: 'Behind the ear', value: 'behind-the-ear', filters: [{ style: ['Receiver in Canal', 'Behind the Ear'] }] },
    ],
  },
  {
    id: 'loss',
    question: 'How would you rate your hearing?',
    type: Widget.SingleChoice,
    options: [
      { text: 'Mild hearing problem', value: 'mild', filters: [{ loss: 'Mild' }] },
      { text: 'Moderate hearing problem', value: 'moderate', filters: [{ loss: 'Moderate' }] },
      { text: 'Severe hearing problem', value: 'severe', filters: [{ loss: ['Severe'] }] },
      { text: 'Profound hearing problem', value: 'profound', filters: [{ loss: ['Profound'] }] },
    ],
  },
  {
    id: 'classification',
    question: 'Are you interested in OTC or prescription options?',
    type: Widget.SingleChoice,
    conditions: [
      {
        questionId: 'loss',
        answers: ['mild', 'moderate'],
      },
    ],
    options: [
      { text: 'Best over-the-counter options', value: 'otc', filters: [{ classification: 'OTC' }] },
      { text: 'Best prescription options (Rx)', value: 'rx', filters: [{ classification: 'rX' }] },
    ],
  },
  {
    id: 'channel',
    question: 'How would you prefer to be fitted with your hearing aid?',
    type: Widget.SingleChoice,
    conditions: [
      {
        questionId: 'classification',
        answers: ['rx', ''],
      },
    ],
    options: [
      { text: 'With an expert at a local clinic', value: 'clinic', filters: [{ channel: 'clinic' }] },
      {
        text: 'Have an expert come to my home',
        conditions: [{ questionId: 'type', answers: ['behind-the-ear'] }],
        value: 'home',
        filters: [{ channel: 'home' }],
      },
      { text: 'On a video call with an expert', value: 'telehealth', filters: [{ channel: 'telehealth' }] },
    ],
  },
  {
    id: 'power',
    question: 'Which power option do you prefer?',
    type: Widget.SingleChoice,
    options: [
      { text: 'Rechargeable batteries', value: 'rechargeable', filters: [{ hardware: 'Rechargeable Batteries' }] },
      { text: 'Replaceable / disposable batteries', value: 'disposable', filters: [{ hardware: 'Disposable Batteries' }] },
    ],
  },
  /*
  {
    id: 'resistance',
    question: 'Do you require a rugged hearing aid? (select all that apply)',
    type: Widget.MultipleChoice,
    options: [
      { text: 'Water resistance', value: 'water', filters: [{ ip: ['IP68', 'IP68+'] }] },
      { text: 'Dust resistance', value: 'dust', filters: [{ ip: ['IP68', 'IP68+'] }] },
    ],
  },
  */
  {
    id: 'features',
    question: 'Which of the following are must haves? (select all that apply)',
    type: Widget.MultipleChoice,
    options: [
      { text: 'Wireless streaming', value: 'wireless', filters: [{ hardware: ['iPhone Streaming', 'Android Streaming'] }] },
      { text: 'Hands-free calling', value: 'hands-free', filters: [{ hardware: 'Hands-Free Calling' }] },
      { text: 'Onboard telecoil', value: 'telecoil', filters: [{ hardware: 'Telecoil' }] },
    ],
  },
  {
    id: 'phone',
    question: 'Do you have a smartphone preference?',
    type: Widget.SingleChoice,
    options: [
      { text: 'Android', value: 'android', filters: [{ hardware: 'Android Streaming' }] },
      { text: 'iPhone', value: 'iphone', filters: [{ hardware: 'iPhone Streaming' }] },
      { text: 'No preference', value: 'no_preference', filters: [] },
    ],
  },
  {
    id: 'sound',
    question: 'Which special sound features do you need? (select all that apply)',
    type: Widget.MultipleChoice,
    options: [
      { text: 'AI noise removal', value: 'ai_noise_removal', filters: [{ software: 'AI Noise Removal' }] },
      { text: 'Frequency lowering', value: 'frequence_lowering', filters: [{ software: 'Frequency Lowering' }] },
      { text: 'Extended bandwidth', value: 'extended_bandwidth', filters: [{ software: 'Extended bandwidth' }] },
    ],
  },
  {
    id: 'must',
    question: 'Which of the following are must haves? (select all that apply)?',
    type: Widget.MultipleChoice,
    options: [
      { text: 'Water or dust resistance', value: 'resistance', filters: [{ ip: ['IP68', 'IP68+'] }] },
      { text: 'Fall detection', value: 'fall_detection', filters: [{ software: 'Fall Detection' }] },
      { text: 'Tap controls', value: 'tap_controls', filters: [{ hardware: 'Tap Controls' }] },
    ],
  },

  /*
  {
    id: 'onboard',
    question: 'Which do you prefer for onboard controls?',
    type: Widget.SingleChoice,
    options: [
      { text: 'Push button', value: 'push_button', filters: [{ hardware: 'Push Button' }] },
      { text: 'Volume rocker', value: 'volume_rocker', filters: [{ hardware: 'Volume Rocker' }] },
      { text: 'Tap controls', value: 'tap_controls', filters: [{ hardware: 'Tap Controls' }] },
    ],
  },
  */
  {
    id: 'final',
    type: Widget.Final,
    question: 'Final Selection',
    options: [],
  },
];
