import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import ImageWrapper from 'components/common-n4/image';
import * as Popover from 'components/common-n4/popover';
import { Skeleton } from 'components/common-n4/skeleton';
import SvgIcon from 'components/ui/svg_icon';
import { useApiData } from 'hooks/use_api_data';
import { useIsMediumOrMobileView } from 'hooks/use_is_mobile_view';
import logger from 'lib/logger';
import { formatDate, normalizeUrl } from 'lib/utils';

import styles from './article.module.scss';

import ExpandedArticle from '../nav-expanded/article';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'nav/Article' });

/* eslint-disable-next-line no-unused-vars */
const NavArticle = ({ blok, story, ...props }) => {
  log.debug('blok: %o', blok);
  const { navArticles } = useApiData();
  const imagePosition = blok?.image_position?.replace(';', '');
  const [hasImageLoaded, setHasImageLoaded] = React.useState(false);
  const isMediumOrLessViewport = useIsMediumOrMobileView();
  const [date, setDate] = React.useState();
  const expand =
    story?.full_slug?.endsWith('components/nav') &&
    !/no-expand/.test(story.content.debug) &&
    (typeof window === 'undefined' || !/no-expand/.test(window.location.search));
  const { close } = Popover.usePopoverContext(expand); // if expand is true, it will ignore popover missing context

  const { slug, large } = blok;
  const article = navArticles[slug];
  const [titleOverride, imageOverride] = [blok.title_override, blok.image_override];

  React.useEffect(() => {
    // doing this hear to avoid hydration issue with time zone differences
    setDate(formatDate(article.republished_at || article.published_at));
  }, [article.republished_at, article.published_at]);

  if (expand) {
    return <ExpandedArticle blok={blok} story={story} {...props} />;
  }

  if (!article) return null;
  // imageOverride is an asset, article.image is probably a url
  const image = imageOverride && imageOverride.id ? imageOverride : normalizeUrl({ url: article.image, origin: 'nav#article' });

  return (
    <div className={`${styles.wrapper} ${!isMediumOrLessViewport && large ? styles.large : ''}`} {...storyblokEditable(blok)}>
      <NextLink
        prefetch={false}
        href={normalizeUrl({ url: article.slug, origin: 'nav/article' })}
        rel={article.noindex ? 'nofollow' : undefined}
        onClick={close}
        data-track={!props.noTrack}
        data-event-name="nav-article"
      >
        {!isMediumOrLessViewport && large ? (
          <section className={styles['article--row']}>
            <figure>
              {image ? (
                <ImageWrapper
                  image={image}
                  imgProps={{ alt: article.title, loading: 'eager', style: { objectPosition: imagePosition || 'center' } }}
                  sizingProps={{ maxWidth: 640 }}
                  onLoaded={() => setHasImageLoaded(true)}
                  origin="nav-article-lg+"
                />
              ) : null}
              {hasImageLoaded ? null : (
                <div className={styles.skeleton}>
                  <Skeleton />
                </div>
              )}
              {hasImageLoaded ? (
                <div className={styles.icon}>
                  <SvgIcon icon="arrow-right-regular" />
                </div>
              ) : null}
            </figure>
            <section className={styles.details}>
              <h4>{titleOverride || article.title}</h4>
              {article.meta_description ? <p>{article.description}</p> : <p>Description</p>}
            </section>
          </section>
        ) : (
          <section className={styles['article--column']}>
            <figure>
              {image && (
                <span style={{ display: hasImageLoaded ? 'block' : 'none' }}>
                  <ImageWrapper
                    image={image}
                    imgProps={{ alt: article.title, loading: 'eager', style: { objectPosition: imagePosition || 'center' } }}
                    sizingProps={{ maxWidth: 320 }}
                    onLoaded={() => setHasImageLoaded(true)}
                    origin="nav-article-md-"
                  />
                </span>
              )}
              {hasImageLoaded ? null : (
                <div className={styles.skeleton}>
                  <Skeleton />
                </div>
              )}
              {hasImageLoaded ? (
                <div className={styles.icon}>
                  <SvgIcon icon="arrow-right-regular" />
                </div>
              ) : null}
            </figure>
            <section className={styles.details}>
              <h4>{titleOverride || article.title}</h4>
              {article.meta_description ? <p className={styles.description}>{article.meta_description}</p> : <p>Description</p>}
              {date ? <div className={styles.date}>{date}</div> : null}
            </section>
          </section>
        )}
      </NextLink>
    </div>
  );
};

export default NavArticle;
