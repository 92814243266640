import * as React from 'react';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';
import ReactMarkdown from 'react-markdown';

import { Avatar } from 'components/common-n4/avatar';
// import { Breadcrumbs } from 'components/common-n4/breadcrumbs';
import ImageWrapper from 'components/common-n4/image';
import { ShareButtons } from 'components/common-n4/share-buttons';
import { Skeleton } from 'components/common-n4/skeleton';
import { useApiData } from 'hooks/use_api_data';
import logger from 'lib/logger';
import { findBlok, findBloks, loadAuthors, loadProducts, sbSidebar } from 'lib/storyblok';
import { cx, formatDate, tw } from 'lib/utils';

import styles from './article.module.scss';
import markdownStyles from './markdown.module.scss';

import { ArticleTitle, ArticleHeader, FeaturedProducts, FeaturedArticles, ArticleTableOfContents } from './article/index';

const log = logger({ category: 'n4/Article' });

const AboutContentDetails = ({ title, items, text = '' }) => (
  <section className={styles['about-content-details']}>
    <span className={styles['about-content-details-top']}>{title}</span>
    <span className={styles['about-content-details-bottom']}>
      {items?.map((item, index) => (
        <React.Fragment key={item.id}>
          {item.name}
          {index === items.length - 1 ? null : ', '}
        </React.Fragment>
      )) || text}
    </span>
  </section>
);

const AuthorDetails = ({ title, items, text = '' }) => {
  const authors = items
    ?.filter((item) => !!item?.name)
    .map((item) => (
      <div key={item.id}>
        {item.slug ? (
          <NextLink prefetch={false} key={item.id} className={styles['author-link']} href={`/authors/${item.slug}`}>
            {item.name}
          </NextLink>
        ) : (
          <span key={item.id}>{item.name}</span>
        )}
      </div>
    ));
  return (
    <section className={styles['about-content-details']}>
      <span className={styles['about-content-details-top']}>{title}</span>
      <div className={styles['about-content-details-bottom']}>{authors || text}</div>
    </section>
  );
};

const extractAuthors = ({ blok, apiAuthors }) => {
  const authors = {
    primary: [],
    reviewers: [],
  };
  if (!apiAuthors) {
    return authors;
  }
  if (blok.primary_author) {
    if (blok.primary_author instanceof Array) {
      authors.primary.push(apiAuthors[blok.primary_author[0]]);
    } else {
      authors.primary.push(apiAuthors[blok.primary_author]);
    }
  }
  if (blok.additional_authors) {
    authors.primary.push(...blok.additional_authors.map((a) => apiAuthors[a]));
  }
  if (authors.primary.length === 0) {
    if (blok.author_other) {
      authors.primary.push({ id: -1, name: blok.author_other });
    } else {
      authors.primary.push({ id: -1, name: 'Staff' });
    }
  }

  if (blok.expert_reviewers) {
    authors.reviewers.push(...blok.expert_reviewers.map((r) => apiAuthors[r]));
  }
  return authors;
};

const About = ({ blok, story }) => {
  const [published, setPublished] = React.useState('');
  const [updated, setUpdated] = React.useState('');
  const api = useApiData();
  const [authors, setAuthors] = React.useState(extractAuthors({ blok, apiAuthors: api.authors }));

  React.useEffect(() => {
    setPublished(formatDate(blok.published || story.published_at || story.created_at));
    if (blok.updated) {
      setUpdated(formatDate(blok.updated));
    }
    const fn = async () => {
      await loadAuthors({ story, api, log });
      setAuthors(extractAuthors({ blok, apiAuthors: api.authors }));
    };
    fn();
  }, [story, blok, api]);

  const { primary, reviewers } = authors;

  return (
    <section className={`${styles.about} mb-8 mt-8`}>
      <section className={`${styles['about-content']} flex w-full flex-wrap items-start justify-between`}>
        <div className="flex w-full flex-wrap items-start md:space-x-8 md:pt-0 lg:w-auto">
          <AuthorDetails title="Written by" items={primary} />
          {reviewers.length > 0 && <AuthorDetails title="Reviewed by" items={reviewers} />}
          {updated ? <AboutContentDetails title="Updated on" text={updated} /> : <AboutContentDetails title="Published on" text={published} />}
        </div>
        <div className={`${styles.share} -mb-2 hidden w-full items-center md:mt-0 md:flex md:w-auto`}>
          <div className="flex w-full justify-start md:justify-end">
            <ShareButtons.Primary />
          </div>
        </div>
      </section>
    </section>
  );
};

const Image = ({ blok }) => {
  const [hasImageLoaded, setHasImageLoaded] = React.useState(false);
  const imagePosition = blok?.image_position?.replace(';', '');
  const image = blok.hero_image;

  // This assumes image is an asset
  if (!image?.filename) {
    return null;
  }

  return (
    <figure className={tw(styles.image, 'animate-fadeIn')}>
      {image ? (
        <ImageWrapper
          image={image}
          imgProps={{ alt: blok.hero_image.alt || blok.title, style: { objectPosition: imagePosition || 'center' } }}
          onLoaded={(source) => {
            log.debug(`in onload for ${image.filename}: ${source}`);
            setHasImageLoaded(true);
          }}
          origin="n4-article-image"
        />
      ) : null}
      {hasImageLoaded ? null : (
        <div className={styles.skeleton}>
          <Skeleton />
        </div>
      )}
    </figure>
  );
};

const expandBloks = (blok) => {
  if (blok instanceof Array) {
    log.silly('array in: %o', blok);
    const arrayBlocks = blok.map((b) => expandBloks(b));
    log.silly('array out: %o', arrayBlocks);
    return arrayBlocks;
  }
  if (blok.component === 'n4-nested-blocks') {
    log.silly('nested in %o', blok);
    const nestedBlocks = expandBloks(blok.blocks || []);
    log.silly('nested out: %o', nestedBlocks);
    return nestedBlocks;
  }
  log.silly('single in: %o, out: %o', blok, blok);
  return blok;
};

const Content = ({ blok, story, ...props }) => {
  log.debug('Article content');
  log.debug('original blok: %o', blok);
  log.silly('bloks before: %o', blok.body);
  const nested = expandBloks(blok.body || []);
  log.debug('bloks after expanding: %o', nested);
  const bloks = nested.flat(10);
  log.debug('bloks flattend: %o', bloks);

  return (
    <section className={tw(styles.content, 'animate-fadeIn')}>
      <section className={styles.article}>
        {bloks.map((articleBlok) => {
          if (articleBlok.component === 'n4-markdown') {
            return (
              <section key={articleBlok._uid} className={cx(styles['article-container'], styles['article-container-markdown'], 'mkdn')}>
                <StoryblokComponent key={articleBlok._uid} blok={articleBlok} story={story} {...props} {...storyblokEditable(articleBlok)} />
              </section>
            );
          }
          return <StoryblokComponent key={articleBlok._uid} blok={articleBlok} story={story} {...props} {...storyblokEditable(articleBlok)} />;
        })}
      </section>
    </section>
  );
};

export const Author = ({ author }) => {
  const imageSource = author?.avatar_url?.includes('https:') ? author.avatar_url : `${'https:'}${author.avatar_url}`;

  return (
    <>
      <section className={styles['author-top']}>
        <section className={styles['author-content']}>
          <NextLink prefetch={false} href={`/authors/${author.slug}`}>
            <section className={styles['author-intro']}>
              <Avatar.Root>
                <Avatar.Image src={imageSource} />
                <Avatar.Fallback />
              </Avatar.Root>
              <section className={styles.intro}>
                <h4>{author?.name}</h4>
                <span>{author?.about_brief}</span>
              </section>
            </section>
          </NextLink>
        </section>
        <section className={styles['author-connect']}>{author?.social_links ? <ShareButtons.Primary links={author.social_links} /> : null}</section>
      </section>
      {author?.about_markdown && (
        <section className={cx(styles['author-description'], markdownStyles['markdown--author-bio'])}>
          <ReactMarkdown>{author.about_markdown}</ReactMarkdown>
        </section>
      )}
    </>
  );
};

const Authors = ({ blok }) => {
  const { authors } = useApiData();
  if (!authors) {
    return null;
  }
  const allIds = [];
  if (blok.primary_author instanceof Array) {
    allIds.push(...blok.primary_author);
  } else {
    allIds.push(blok.primary_author);
  }
  if (blok.additional_authors?.length) {
    allIds.push(...blok.additional_authors);
  }
  if (blok.author_other) {
    allIds.push(blok.author_other);
  }
  /*
  if (blok.expert_reviewers) {
    allIds.push(...blok.expert_reviewers);
  }
  */
  const all = allIds.map((id) => authors[id]).filter((a) => !!a);
  if (!all.length) {
    return <div className="mb-10" />;
  }
  return (
    <>
      <Divider mobileHide />
      <section className={styles.authors}>
        {/* <span className={styles['authors-intro']}>About the author{all.length === 1 ? '' : 's'}:</span> */}
        <ul className={styles['authors-list']}>
          {all.map((author) => (
            <li key={author.id} className={styles.author}>
              <Author author={author} />
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

const Tags = ({ story, title }) => {
  const rejectedTags = ['product page'];

  return (
    <section className={styles['tags-container']}>
      <section className={styles.share}>
        <span>Share this post</span>
        <ShareButtons.Primary title={title} />
      </section>
      {story?.tag_list.length ? (
        <ul className={styles.tags}>
          {story.tag_list
            .filter((tag) => !rejectedTags.includes(tag) && !tag.startsWith('Hub:'))
            .map((tag) => (
              <li key={tag}>{tag}</li>
            ))}
        </ul>
      ) : null}
    </section>
  );
};

const Divider = ({ mobileHide, shouldBeSpaceless }) => (
  <div className={cx(styles.divider, mobileHide && styles['divider--mobile-hide'], shouldBeSpaceless && styles['divider--spaceless'])} />
);

const AffiliateDisclosure = () => (
  <section className={cx(styles['affiliate-disclosure'])}>
    Disclaimer: HearingTracker may earn a commission from this link. Please see our{' '}
    <NextLink prefetch={false} href="/ethics-statement">
      ethics statement
    </NextLink>
    .
  </section>
);

const extractListicleModel = ({ blok, models }) => {
  const model = blok.form_factor
    ? models[blok.form_factor]
    : Object.values(models).find((m) => m.hearing_aids.some((ha) => ha.id === blok.hearing_aid)) || models[`ha-${blok.hearing_aid}`];
  if (model) {
    const { image, name: title, ...rest } = blok;
    const listicle = { ...model, ...rest, title };
    // TODO: verify that we want to use the blok image for the thumbnail
    if (image?.filename) {
      listicle.image = blok.image;
    }
    return listicle;
  }
  return null;
};

const extractListicles = ({ story, api }) => {
  const pageConfig = findBlok(story, 'n4-page-config');
  if (!pageConfig?.listicle) {
    return null;
  }
  const listicles = findBloks(story, 'n4-listicle-product');
  if (!listicles?.length) {
    return null;
  }
  return listicles.map((blok) => extractListicleModel({ blok, models: api.models })).filter((l) => !!l);
};

// Note; should probably do the api and effect one level up and pass down articles
// If there are article, we show them, else if there are products, show them, else
// if there is a toc, show, else no sidebar and full width
const Article = ({ blok, story, ...props }) => {
  const api = useApiData();
  const [sidebar, setSidebar] = React.useState(api.releasePage ? {} : api.sidebar || {});
  const [listicles, setListicles] = React.useState(extractListicles({ story, api }));

  React.useEffect(() => {
    if (api.releasePage) {
      return;
    }
    const fn = async () => {
      // In case they changed the products in the editor to ones we haven't loaded yet
      await loadProducts({ story, api, log });
      const results = await sbSidebar({ story, api, log });
      setSidebar(results || {});
      const listicleResults = await extractListicles({ story, api });
      setListicles(listicleResults);
    };
    fn();
  }, [story, api]);

  // Note: only one of these will be populated per the page-config and other rules
  const { articles, products, toc } = sidebar;
  const isProductPage = story.content.blocks.some((b) => b.component === 'n4-product-config');
  const pageConfig = findBlok(story, 'n4-page-config');
  const isListiclePage = pageConfig?.listicle;
  const hasNoSideBar = !articles && !products && !toc;

  log.debug('blok: %o', blok);
  return (
    <section className={cx(styles['n4-article'], blok.className, blok.component)} {...storyblokEditable(blok)} id="article">
      {/* {!props.hideBreadcrumbs && (
        <div className="mb-[2rem] max-w-[100%]">
          <Breadcrumbs data={props.breadcrumbs} />
        </div>
      )} */}
      {blok.top_divider && <Divider shouldBeSpaceless />}
      {isListiclePage && (
        <>
          <ArticleHeader blok={blok} story={story} listicles={listicles} className="" />
          {hasNoSideBar && <hr className="mb-12 mt-6 hidden w-full border-t border-navy-20 lg:block" />}
        </>
      )}
      {!isListiclePage && (
        <div className="max-w-[916px]">
          <ArticleTitle blok={blok} story={story} />
        </div>
      )}
      {blok.affiliate_disclosure && <AffiliateDisclosure />}
      {!isListiclePage && <About blok={blok} story={story} />}
      <div
        className={tw(
          'content-container self-stretch lg:flex lg:justify-between ',
          isProductPage && 'mt-8',
          hasNoSideBar && 'lg:justify-center',
          isListiclePage ? 'listicle-hide-sidebar:justify-center' : 'hide-sidebar:justify-center'
        )}
      >
        <div
          className={tw(
            'main-content',
            isProductPage && 'w-full laptop:max-w-[640px]',
            isListiclePage ? 'listicle-show-sidebar:basis-[810px]' : 'show-sidebar:basis-[916px]'
          )}
        >
          {blok.hero_image?.filename && (
            <div className={styles['article-info-image']}>
              <Image blok={blok} />
            </div>
          )}
          <Content blok={blok} story={story} {...props} />
          <section className={styles['article-container']}>
            <Tags story={story} title={props.title} />
            <Authors blok={blok} />
          </section>
        </div>
        {(articles || products || toc) && (
          <aside className={tw('sidebar max-w-[400px] flex-1', isListiclePage ? 'listicle-hide-sidebar:hidden' : 'hide-sidebar:hidden')}>
            <div className="sticky top-[90px] flex flex-col gap-12">
              {articles && <FeaturedArticles articles={articles} />}
              {products && <FeaturedProducts products={products} />}
              {toc && <ArticleTableOfContents toc={toc} />}
            </div>
          </aside>
        )}
      </div>
    </section>
  );
};

export default Article;
