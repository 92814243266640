/* eslint-disable max-len */
import React from 'react';

import NextLink from 'next/link';
import { ClassNameValue } from 'tailwind-merge';

import ImageWrapper from 'components/common-n4/image';
import { ImageSource } from 'components/common-n4/image/types';
import { formatDate, normalizeUrl, tw } from 'lib/utils';

import { Panel } from './panel';

interface ArticleType {
  title: string;
  image: ImageSource;
  slug: string;
  published_at: Date;
  republished_at: Date;
  description: string;
}

interface ArticleProps {
  article: ArticleType;
  className?: ClassNameValue;
}

const origin = 'article/featured-articles';

const Article: React.FC<ArticleProps> = ({ article, className }) => {
  const [publishedDate, setPublishedDate] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (article.republished_at || article.published_at) {
      setPublishedDate(formatDate(article.republished_at || article.published_at));
    }
  }, [article.republished_at, article.published_at]);

  return (
    <div className={tw('flex gap-5 border-b border-navy-10 pb-5', className)}>
      {article.image && (
        <NextLink
          prefetch={false}
          href={normalizeUrl({ url: article.slug, origin })}
          className="shrink-0"
          data-crack
          data-event-name="sidebar-article-click"
          data-origin={origin}
        >
          <ImageWrapper
            key={article.title}
            image={article.image}
            origin={origin}
            imgProps={{ className: 'bg-white rounded-[10px] w-[128px] h-[96px] object-cover' }}
          />
        </NextLink>
      )}
      <div className="flex flex-col justify-between">
        <div className="line-clamp-3 text-base leading-[140%] tracking-tight text-navy">
          <NextLink prefetch={false} href={normalizeUrl({ url: article.slug, origin })} data-track data-event-name="sidebar-article">
            {article.title}
          </NextLink>
        </div>
        {publishedDate && <div className="text-sm leading-5 tracking-tight text-lapis">{publishedDate}</div>}
      </div>
    </div>
  );
};

interface FeaturedArticlesProps {
  articles: ArticleType[];
}

export const FeaturedArticles: React.FC<FeaturedArticlesProps> = ({ articles }) => {
  return (
    <Panel className="flex flex-col gap-6">
      <div className="text-xl leading-7 tracking-tight text-navy">Recent Articles</div>
      {articles?.map((article, index) => <Article key={article.title} article={article} className={index === articles.length - 1 && 'border-none pb-0'} />)}
      <NextLink prefetch={false} href="/news" className="leading-2 self-start text-xs tracking-tight text-lapis underline">
        View all
      </NextLink>
    </Panel>
  );
};
