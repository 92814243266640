import { ImageSource } from 'components/common-n4/image/types';

export enum Widget {
  SingleChoice = 'single-choice',
  MultipleChoice = 'multiple-choice',
  Range = 'range',
  Final = 'final',
}

export interface Condition {
  questionId: string;
  answers: string[];
}

export type FilterTypes = 'tags' | 'style' | 'hardware' | 'software' | 'loss' | 'channel' | 'classification' | 'ip' | 'styleName';

export interface Option {
  text: string;
  value: string;
  filters: Partial<Record<FilterTypes, string | string[]>>[];
  conditions?: Condition[];
}

export interface Question {
  id: string;
  question: string;
  type: Widget;
  conditions?: Condition[];
  options: Option[];
}

export type PriceChannel = 'clinic' | 'online' | 'telehealth';

export interface WizardOffer {
  price: string;
  channels: PriceChannel[];
}

export interface WizardPrice {
  min: string;
  max: string;
  clickText?: string;
  url: string;
  seller: string;
  partnerType: string;
  offers: WizardOffer[];
}

export interface Product {
  hearingAidId: number;
  modelId: number;
  slug: string;
  releaseSlug: string;
  path: string;
  name: string;
  fullName: string;
  releaseName: string;
  filters: Record<FilterTypes, string | string[]>;
  image: ImageSource;
  price: WizardPrice;
  score?: number;
  keyFeatures: string[];
  base?: string;
  brand: string;
  sortOrder: number;
  level: number;
}

export enum UserAction {
  Restart = 'restart',
  Answer = 'answer',
  PriceRange = 'price-range',
  Cancel = 'cancel',
  Back = 'back',
  Next = 'next',
}

export enum SystemAction {
  Initialize = 'initialize',
  SetMatches = 'set-matches',
  Fetching = 'fetching',
  Error = 'error',
}

export enum Event {
  Next = 'Next',
  Back = 'Back',
  Answer = 'Answer',
  PriceRange = 'PriceRange',
  Restart = 'Restart',
  SetMatches = 'SetMatches',
  Error = 'Error',
}

export type Answers = Record<string, string | string[]>;

export interface PriceRange {
  min: number;
  max: number;
}

export interface State {
  step: number;
  questions: Question[];
  answers: Answers;
  priceRange?: number[];
  products: Product[];
  segment: number;
  position: string;
  segmentOrigin: string;
  fetching: boolean;
  userPriceRange: PriceRange;
}

export interface StateUpdate {
  segment?: number;
  products?: Product[];
  answer?: string | string[];
  error?: boolean;
  message?: string;
  userPriceRange?: PriceRange;
}

export interface Action {
  type: UserAction | SystemAction;
  payload?: StateUpdate;
}

export interface ContentProps {
  onAnwserSelect?: () => void;
}

export interface MultiFilter {
  answers: Answers;
  filters: Partial<Record<FilterTypes, string | string[]>>;
}
