/* Components used in N4 pages. Most should be dynamic but as more of the site uses this,
 * they can be moved to standard so they're loaded in the main bundle.
 * Trying non-dynamic - since these pages are pre-rendered, not sure what dynamic really
 * buys us. Dynamic could be used for content that is loaded in response to some user
 * action or for something that is only used on one page. Maybe this will get rid of
 * those annoying hydration errors.
 */
import Accessory from './accessory';
import Ad from './ad';
import Article from './article';
import ArticleCard from './article_card';
import ArticleGrid from './article_grid';
import AudioFile from './audio-file';
import Blockquote from './blockquote';
import BrandReleases from './brand-releases';
import CallToAction from './call_to_action';
import ContactForm from './contact-form';
import Container from './container';
import DidYouKnow from './did-you-know';
import Divider from './divider';
import Faq from './faq';
import FooterContainer from './footer_container';
import Hero from './hero';
import homeComponents from './home';
import HTML from './html';
import Image from './image';
import ListicleProduct from './listicle-product';
import Markdown from './markdown';
import MetaInformation from './meta_information';
import NavArticle from './nav/article';
import NavCustomLink from './nav/custom_link';
import NavPanel from './nav/panel';
import NavPanelContainer from './nav/panel_container';
import NavPanelTab from './nav/panel_tab';
import NavProduct from './nav/product';
// import navExpandedComponents from './nav-expanded';
import NestedBlocks from './nested-blocks';
import PageConfig from './page_config';
import ProductConfig from './product/product_config';
import ProductReview from './product-review';
import ProductCard from './product_card';
import ProsAndCons from './pros_and_cons';
import Sidebar from './sidebar';
import StaffCard from './staff-card';
import Table from './table';
import TestError from './test-error';
import Wizard from './wizard';
import Youtube from './youtube';

const components = {
  HTML,
  'n4-accessory': Accessory,
  'n4-ad': Ad,
  'n4-article': Article,
  'n4-article-card': ArticleCard,
  'n4-article-grid': ArticleGrid,
  'n4-audio-file': AudioFile,
  'n4-blockquote': Blockquote,
  'n4-brand-releases': BrandReleases,
  'n4-call-to-action': CallToAction,
  'n4-container': Container,
  'n4-contact-form': ContactForm,
  'n4-did-you-know': DidYouKnow,
  'n4-divider': Divider,
  'n4-faq': Faq,
  'n4-footer-container': FooterContainer,
  'n4-hero': Hero,
  'n4-image': Image,
  'n4-listicle-product': ListicleProduct,
  'n4-page-config': PageConfig,
  'n4-markdown': Markdown,
  'n4-meta-information': MetaInformation,
  'n4-nested-blocks': NestedBlocks,
  'n4-product-card': ProductCard,
  'n4-product-config': ProductConfig,
  'n4-product-review': ProductReview,
  'n4-pros-and-cons': ProsAndCons,
  'n4-sidebar': Sidebar,
  'n4-staff-card': StaffCard,
  'n4-table': Table,
  'n4-test-error': TestError,
  'n4-youtube': Youtube,
  'n4-youtube-video': Youtube,
  'nav-article': NavArticle,
  'nav-custom-link': NavCustomLink,
  'nav-panel': NavPanel,
  'nav-panel-container': NavPanelContainer,
  'nav-panel-tab': NavPanelTab,
  'nav-product': NavProduct,
  wizard: Wizard,
  table_v2: Table,
  ...homeComponents,
  // ...navExpandedComponents,
};

export default components;
