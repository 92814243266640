import logger from './logger';
import { track } from './tracking';

const log = logger({ category: 'site-control' });

const urlParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : new URLSearchParams();
const skip = typeof window !== 'undefined' && (window !== window.parent || urlParams.get('gsc') === 'off' || window.skip_gsc);
let loaded = false;

/* eslint-disable max-len */
const emailRegExp =
  /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
/* eslint-enable max-len */

let counter = 0;
const trackGsc = () => {
  if (!window.gsc || typeof window.gsc !== 'function') {
    counter += 1;
    if (counter < 10) {
      setTimeout(trackGsc, 500);
    }
    return;
  }
  window.gsc('onShow', (widgetId, data) => {
    log.debug('gsc show: %o', data);
    track({ event: 'gsc-show', eventType: 'impression', origin: 'gsc-show', properties: { widgetId, data } });
  });
  window.gsc('onOpenUrl', (widgetId, data) => {
    log.debug('gsc open: %o', data);
    track({ event: 'gsc-click', eventType: 'click', origin: 'gsc-openUrl', properties: { widgetId, data } });
  });
  window.gsc('onClose', (widgetId, data) => {
    log.debug('gsc close: %o', data);
    track({ event: 'gsc-close', eventType: 'close-popup', origin: 'gsc-close', properties: { widgetId, data } });
  });
};

const loadGSC = () => {
  if (skip) {
    return;
  }
  log.debug('GSC: loading');
  // Handles the case where a visitor clicks the link (GSC loaded for visitors not users currently)
  /* eslint-disable */
  (function (w, i, d, g, e, t, s) {
    w[d] = w[d] || [];
    t = i.createElement(g);
    t.async = 1;
    t.src = e;
    s = i.getElementsByTagName(g)[0];
    s.parentNode.insertBefore(t, s);
    setTimeout(trackGsc, 500);
  })(window, document, 'gsc', 'script', 'https://l.getsitecontrol.com/8wlqrx4v.js');
  /* eslint-enable */
};

const defer = (method) => {
  if (skip) {
    return;
  }
  // Handles the case where a visitor clicks the link (GSC loaded for visitors not users currently)
  if (typeof gsc === 'function') {
    log.debug('GSC is loaded, calling method');
    method();
  } else {
    log.debug('GSC still not loaded. Deferring again.');
    setTimeout(() => {
      defer(method);
    }, 50);
  }
};

const showGSC = (sId, field) => {
  if (skip) {
    return;
  }
  const id = parseInt(sId, 10);
  log.debug('GSC: showing id: %o, field: %o', id, field);
  // Handles the case where a visitor clicks the link (GSC loaded for visitors not users currently)
  const params = {};
  if (field) {
    const el = document.getElementById(field);
    if (el) {
      const val = el.value;
      if (el.dataset.gscOptional) {
        params[el.dataset.gscField] = val;
      } else if (val) {
        if (el.getAttribute('type') === 'email' && !emailRegExp.test(val)) {
          el.style.borderColor = 'red';
          el.setCustomValidity('A valid e-mail address is required');
          el.reportValidity();
          return;
        }
        el.setCustomValidity('');
        if (el.dataset.gscField) {
          params[el.dataset.gscField] = val;
        } else {
          params[field] = val;
        }
        el.style.borderColor = '';
        el.setAttribute('placeholder', '');
      } else if (el.dataset.gsc.optional !== 'true') {
        el.style.borderColor = 'red';
        el.setCustomValidity('');
        el.setAttribute('placeholder', `Enter ${field}`);
        return;
      }
    }
  }
  log.debug('GSC params: %o', params);
  if (typeof gsc === 'undefined') {
    log.debug('Kicking off defer');
    loadGSC();
    defer(() => {
      window.gsc('params', params).then(() => {
        window.gsc('show', id);
      });
    });
  } else {
    window.gsc('params', params).then(() => {
      window.gsc('show', id);
    });
  }
};

// window.showGSC = showGSC;

const initSiteControl = () => {
  if (skip || loaded) {
    return;
  }
  loaded = true;
  // Handles the case where a visitor clicks the link (GSC loaded for visitors not users currently)
  setTimeout(
    () => {
      loadGSC();
    },
    parseFloat(urlParams.get('gsc')) || 2000
  );
  document.addEventListener('keydown', (e) => {
    if (e.target.dataset.gsc) {
      if (e.key === 'Enter') {
        e.preventDefault();
        showGSC(e.target.dataset.gsc, e.target.getAttribute('id'));
      } else {
        e.target.setCustomValidity('');
      }
    }
  });
  document.addEventListener('click', (e) => {
    if (e.target.classList.contains('gsc') && e.target.dataset.gsc) {
      showGSC(e.target.dataset.gsc, e.target.dataset.gscField);
    } else if (e.target && e.target.tagName === 'I' && e.target.parentElement && e.target.parentElement.dataset.gsc) {
      const p = e.target.parentElement;
      showGSC(p.dataset.gsc, p.dataset.gscField);
    }
  });
};

export default initSiteControl;
