import React, { useContext } from 'react';

import { Button } from 'components/common-n4/button';
import { tw } from 'lib/utils';

import { type Answers, type ContentProps, type Option, UserAction } from './types';
import { matchesConditions } from './utils';

const filterOptions = (question: string, answers: Answers, options: Option[]): Option[] =>
  options.filter((option) => matchesConditions({ question, answers, conditions: option.conditions }));

import { WizardContext } from '.';

export const MultipleChoice: React.FC<ContentProps> = ({ onAnwserSelect }) => {
  const { state, dispatch } = useContext(WizardContext);
  const question = state.questions[state.step];
  const currentAnswers = (state.answers[question.id] as string[]) || [];

  const handleChange = (answer: string) => {
    onAnwserSelect?.();
    let newAnswers: string[];
    if (currentAnswers.includes(answer)) {
      newAnswers = currentAnswers.filter((a) => a !== answer);
    } else {
      newAnswers = currentAnswers.concat(answer);
    }
    dispatch({ type: UserAction.Answer, payload: { answer: newAnswers } });
  };

  return filterOptions(question.id, state.answers, question.options).map((option) => {
    const Component = currentAnswers.includes(option.value) ? Button.Primary : Button.Secondary;
    return (
      <Component onClick={() => handleChange(option.value)} key={option.value} className={tw('!w-full !justify-center border !px-[18px] !py-[10px]')}>
        {option.text}
      </Component>
    );
  });
};
